var feature_gates = function () {
  var modal = $("#upgrade");
  var plan = $(modal).attr("data-plan");
  window.plan_type = $(modal).attr("data-plan-type");
  var plan_interval = $(modal).attr("data-plan-interval");
  var partner_type = $(modal).attr("data-partner-type");
  var website_premium = $(modal).attr("data-website-premium");
  var website_plan = $(modal).attr("data-website-plan");
  var cta_count = $(modal).attr("data-cta-count");


  // limit account features
  if (plan_type) {

    $(".funnel-vault").on("click", function (event) {
      subscriptions.upgrade_modal(
        event,
        false,
        "access the funnel vault and premium templates.",
        "2024_pro",
        "Triggered feature CTA – Vault"
      );
    });

    $(".upgrade-button").on("click", function (e) {
      if ($(e.target).is("a") == false || $(this).hasClass("upgrade-button")) {
        if (plan_type == "free" || website_premium == "false" || plan_type == '2024_funnels') {
          subscriptions.upgrade_modal(
            event,
            false,
            "try the full funnel builder.",
            "2024_pro",
            "Triggered feature CTA – Paid Features"
          );
        }
      }
    });

    $(".views-limit").on("click", function (e) {
      if ($(e.target).is("a") == false || $(this).hasClass("upgrade-button")) {
        if (plan_type == "free" || website_premium == "false") {
          subscriptions.upgrade_modal(
            event,
            false,
            "remove the limit of 1,000 monthly views.",
            "2024_funnels",
            "Triggered feature CTA – Visitor Limit"
          );
        }
      }
    });

    if (plan_type == "free") {
      $(".affiliate-program").on("click", function (event) {
        subscriptions.upgrade_modal(
          event,
          false,
          "join the affiliate partner program",
          "partner",
          "Triggered feature CTA – Partner"
        );
      });
    }

    if (["agency", "affiliate"].includes(partner_type) == false) {
      $(".partner-program").on("click", function (event) {
        event.preventDefault();
        subscriptions.upgrade_modal(
          event,
          false,
          "join the affiliate partner program",
          "partner",
          "Triggered feature CTA – Partner"
        );
      });
    }

    $(".visitors-limit").on("click", function (e) {
      if ($(e.target).is("a") == false || $(this).hasClass("upgrade-button")) {
        if (plan_type == "free" || website_premium == "false") {
          subscriptions.upgrade_modal(
            event,
            false,
            "get more than 1,000 views per month",
            "2024_funnels",
            "Triggered feature CTA – Visitor Limit"
          );
        }
      }
    });

    if (["free"].includes(plan_type)) {
      $(".website-create").on("click", function () {
        subscriptions.upgrade_modal(
          event,
          false,
          "manage multiple websites",
          "2024_funnels",
          "Triggered feature CTA – Website Limit"
        );
      });
    }

    if (
      [
        "free",
        "2024_funnels",
        "2024_pro",
        "marketer",
        "2021_pro",
        "2022_pro",
        "2023_build", 
        "2023_launch",
      ].indexOf(plan_type) > -1
    ) {
      $("#accounts-logo-upload").on("click", function (event) {
        subscriptions.upgrade_modal(
          event,
          false,
          "add custom branding to your account",
          plan_type == "2022_pro" ? "team" : ['2023_build', '2023_launch'].includes(plan_type) ? '2023_pro' : "2024_advanced",
          "Triggered feature CTA – Branded Account"
        );
      });
    }

    if (
      [
        "free",
        "2024_funnels",
        "2024_pro",
        "marketer",
        "2021_pro",
        "2022_pro",
        "2023_build", 
        "2023_launch",
      ].indexOf(plan_type) > -1
    ) {
      $(".edit_account").on("submit", function (e) {
        if (
          $(this).find('select[name="account[from_email]"]').length > 0 &&
          $(this).find('select[name="account[from_email]"]').val() !==
            "app@convertflow.com"
        ) {
          e.preventDefault();
          subscriptions.upgrade_modal(
            event,
            false,
            "send custom branded lead alerts from your team",
            plan_type == "2022_pro" ? "team" : ['2023_build', '2023_launch'].includes(plan_type) ? '2023_pro' : "2024_advanced",
            "Triggered feature CTA – Branded Alerts"
          );
          return false;
        } else {
          return true;
        }
      });
    }

    // limit website features

    // override account plan with websites plan if website on lesser plan
    if (['2024_funnels', '2024_pro', '2024_advanced', '2024_enterprise'].includes(plan_type) && (website_premium == "false")) {
      window.plan_type = 'free';
    }
    if (['2024_pro', '2024_advanced', '2024_enterprise'].includes(plan_type) && (website_premium == "true" && [null, '', '2024_funnels'].includes(website_plan))) {
      window.plan_type = '2024_funnels';
    }
    if (['2024_advanced', '2024_enterprise'].includes(plan_type) && (website_premium == "true" && ['2024_funnels', '2024_pro'].includes(website_plan))) {
      window.plan_type = '2024_pro';
    }

    $(".upgrade-website-button").on("click", function (e) {
      if ($(e.target).is("a") == false || $(this).hasClass("upgrade-website-button")) {
        if (plan_type == "free" || website_premium == "false") {
          subscriptions.upgrade_modal(
            event,
            false,
            "go live on your own website.",
            "2024_funnels",
            "Triggered feature CTA – Paid Features"
          );
        }
      }
    });


    if (
      plan.indexOf("shopify") == -1 &&
      (plan == "free" || website_premium == "false")
    ) {
      $(document).on(
        "click",
        ".integration-item[data-plan='funnels'] .integration-connect",
        function (event) {
          event.preventDefault();
          subscriptions.upgrade_modal(
            event,
            false,
            "go live on your website",
            "2024_funnels",
            "Triggered feature CTA – Shopify"
          );
        }
      );
    }

    if (
      (plan_type == "free" || website_premium == "false")
    ) {
      setTimeout(function () {
        $("select[name=\"cta[domain_id]\"]").on("change", function (e) {
          e.preventDefault();
          subscriptions.upgrade_modal(
            event,
            false,
            "go live on your own domain",
            "2024_funnels",
            "Triggered feature CTA – Paid Features"
          );
        });
      }, 100);
    }

    // if (
    //   ["free", "2024_funnels", "2024_pro"].includes(plan_type) ||
    //   website_premium == "false"
    // ) {
    //   $(document).on(
    //     "click",
    //     ".integration-item[data-plan='advanced'] .integration-connect",
    //     function (event) {
    //       event.preventDefault();
    //       subscriptions.upgrade_modal(
    //         event,
    //         false,
    //         "use Advanced integrations",
    //         "2024_advanced",
    //         "Triggered feature CTA – Automations Pro"
    //       );
    //     }
    //   );
    // }

    if (
      (plan_type == "free" || website_premium == "false") &&
      cta_count &&
      parseInt(cta_count) >= 5
    ) {
      setTimeout(function () {
        $(".new-cta").on("click", function () {
          subscriptions.upgrade_modal(
            event,
            false,
            "create more than 5 pages and campaigns",
            "2024_funnels",
            "Triggered feature CTA – CTA Limit"
          );
        });
      }, 100);
    }
    
    if (
      (["2024_funnels", "2023_build"].includes(plan_type) || website_premium == "false") &&
      cta_count &&
      parseInt(cta_count) >= 10
    ) {
      setTimeout(function () {
        $(".new-cta").on("click", function () {
          subscriptions.upgrade_modal(
            event,
            false,
            "create more than 10 pages and campaigns, or archive inactive campaigns to create new ones",
            "2024_pro",
            "Triggered feature CTA – CTA Limit"
          );
        });
      }, 100);
    }

    if (
      (["2024_funnels", "2024_pro"].includes(plan_type) || website_premium == "false") &&
      cta_count &&
      parseInt(cta_count) >= 25
    ) {
      setTimeout(function () {
        $(".new-cta").on("click", function () {
          subscriptions.upgrade_modal(
            event,
            false,
            "create more than 25 pages and campaigns, or archive inactive campaigns to create new ones",
            "2024_advanced",
            "Triggered feature CTA – CTA Limit"
          );
        });
      }, 100);
    }

    if (plan_type == "free" || website_premium == "false") {
      $(".domain-create").on("click", function () {
        subscriptions.upgrade_modal(
          event,
          false,
          "add custom domains for landing pages",
          "2024_funnels",
          "Triggered feature CTA – Custom Domain"
        );
      });
    }

    if (plan_type == "free" || website_premium == "false") {
      $("#website_powered_by")
        .unbind("click")
        .on("click", function (event) {
          subscriptions.upgrade_modal(
            event,
            false,
            'remove "powered by" links',
            "2024_funnels",
            "Triggered feature CTA – Remove Powered By"
          );
        });
    }

    if (
      ["free", "2024_funnels", "2022_pro", "2023_build", "2023_launch"].indexOf(
        plan_type
      ) > -1 ||
      website_premium == "false"
    ) {
      $(
        "body[data-controller='broadcasts'], body[data-controller='campaign_templates']"
      ).on("click", ".broadcast-create", function (event) {
        $("#newBroadcastModal").remove();
        subscriptions.upgrade_modal(
          event,
          false,
          "create broadcast campaigns",
          plan_type == '2022_pro' ? 'team' : ['2023_build', '2023_launch'].includes(plan_type) ? '2023_pro' : "2024_pro",
          "Triggered feature CTA – Broadcasts"
        );
      });
    }

    if (
      ["free", "2024_funnels", "2024_pro"].indexOf(
        plan_type
      ) > -1 ||
      website_premium == "false"
    ) {
      $(document).on("click", ".area-new", function (event) {
        event.preventDefault();
        $("#areaModal").modal('hide');
        $("#areaModal").remove();
        subscriptions.upgrade_modal(
          event,
          false,
          "launch targeted embeds",
          "2024_advanced",
          "Triggered feature CTA – Targeted Embeds"
        );
      });
    }



    if (
      $.inArray(plan_type, [
        "free",
        "2024_funnels",
        "2024_pro",
        "2021_pro",
        "2022_pro",
        "2023_build", 
        "2023_launch"
      ]) > -1 ||
      website_premium == "false"
    ) {
      $("input[data-name='segment-events']").on("change", function (e) {
        e.preventDefault();
        $(e.target).prop("checked", false);
        subscriptions.upgrade_modal(
          event,
          false,
          "enable the events stream for Segment",
          plan_type == "2022_pro" ? "team" : ["2023_build", "2023_launch"].includes(plan_type) ? "2023_pro" : "2024_advanced",
          "Triggered feature CTA – Automations Pro"
        );
      });
  
      $("input[data-name*='segment']").each(function (i, input) {
        $(input).on("focus", function (e) {
          e.preventDefault();
          $(e.target).val("");
          subscriptions.upgrade_modal(
            event,
            false,
            "enable the events stream for Segment",
            plan_type == "2022_pro" ? "team" : ["2023_build", "2023_launch"].includes(plan_type) ? "2023_pro" : "2024_advanced",
            "Triggered feature CTA – Automations Pro"
          );
        });
      });
    }
  
    if (
      ["free", "2024_funnels", "2024_pro"].includes(plan_type) ||
      website_premium == "false"
    ) {

      $("#google-analytics-data-layer-toggle")
        .unbind("click")
        .on("click", function (event) {
          subscriptions.upgrade_modal(
            event,
            false,
            'enable events streaming to a custom dataLayer variable',
            "2024_advanced",
            "Triggered feature CTA – Events Streaming"
          );
        });
    }
  
    if (["free", "2024_funnels", "2024_pro"].includes(plan_type) || website_premium == "false") {
      $(".conditions-groups-create").on("click", function (event) {
        event.preventDefault();
        $('#newConditionsGroupModal').remove();
  
        subscriptions.upgrade_modal(
          event,
          false,
          "save dynamic visitor segments",
          "2024_advanced",
          "Triggered feature CTA – Visitor Segments"
        );
      });
    }
  }
  

  $(
    '.pricing-table a[data-toggle="collapse"], .feature-gate a[data-toggle="collapse"]'
  ).each(function (i, a) {
    $(a)
      .off("click.pricing_table")
      .on("click.pricing_table", function () {
        $(a).parents(".panel").toggleClass("open");
      });
  });
};

export default feature_gates;
