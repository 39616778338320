import React, { useState, useEffect, useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import useProducts from "../products/useProducts";
import useProductVariants from "../product_variants/useProductVariants";
import useFields from "../fields/useFields";
import useContentToggle from "../content_toggles/useContentToggle";
import useContentLists from "../content_lists/useContentLists";
import useFieldOptions from "../field_options/useFieldOptions";
import useActionGroups from "../action_groups/useActionGroups";
import VariantBuilderSlideOutPanel from "./VariantBuilderSlideOutPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import arePropsEqual from "../../utils/arePropsEqual";
import htmlToLabel from "../../utils/htmlToLabel";
import useSections from "../sections/useSections.js";
import useProductRecommendations from "../product_recommendations/useProductRecommendations.js";
import VariantBuilderSettingCheckbox from "../variants/VariantBuilderSettingCheckbox";

const VariantBuilderSettingItemV2 = (props) => {
  const { Sections } = useSections();
  const [contextData, builder] = useContext(VariantContextData);

  const [actionButtonDisabled, setActionButtonDisabled] = useState(false);

  let hookToUse;

  if (props.object_type == "fields") {
    const { Fields } = useFields();
    hookToUse = Fields;
  }

  if (props.object_type == "content_list_items") {
    const { ContentLists } = useContentLists();
    hookToUse = ContentLists;
  }

  if (props.object_type == "field_options") {
    const { FieldOptions } = useFieldOptions();
    hookToUse = FieldOptions;
  }

  if (props.object_type == "content_toggle_items") {
    const { ContentToggle } = useContentToggle(Sections);
    hookToUse = ContentToggle;
  }

  if (props.object_type == "products") {
    const { Products } = useProducts();
    hookToUse = Products;
  }

  if (props.object_type == "product_variants") {
    const { ProductVariants } = useProductVariants();
    hookToUse = ProductVariants;
  }

  if (props.object_type == "action_groups") {
    const { ActionGroups } = useActionGroups();
    hookToUse = ActionGroups;
  }

  if (props.object_type == "product_recommendations") {
    const { ProductRecommendations } = useProductRecommendations();
    hookToUse = ProductRecommendations;
  }

  const defaultLabelMaxLength = props.label_max_length || 15;
  const defaultLabelmaxLengthOnHover = props.label_hover_max_length || 8;
  const [titleLabelMaxLength, setTitleLabelMaxLength] = useState(
    defaultLabelMaxLength
  );

  const [panelVisibility, setPanelVisibility] = useState(undefined);

  const modalId = `${props.object_type}-${props.object_id}-modal-${props.setting_name}`;

  const parsedItemLabel = () => {
    const lower_title = props.title.replace(/(<([^>]+)>)/gi, "");

    return lower_title && lower_title.length > titleLabelMaxLength
      ? lower_title.substr(0, titleLabelMaxLength).trim() + "..."
      : lower_title;
  };

  const uniqueIdentifier = Math.random().toString(16).slice(2);

  useEffect(() => {
    let itemWrapper = document.querySelector(
      `.cf-item-v2[data-object-id="${props.object_id}"][data-unique-identifier="${uniqueIdentifier}"]`
    );

    itemWrapper.onmouseover = function (e) {
      setTitleLabelMaxLength(defaultLabelmaxLengthOnHover);

      if (props.hide_handle !== true && props.draggable !== "false") {
        itemWrapper.querySelector(".cf-item-handler").style.visibility = "visible";
      }

      itemWrapper.querySelector(".cf-item-controls").style.display = "flex";
    };

    itemWrapper.onmouseleave = function (e) {
      setTitleLabelMaxLength(defaultLabelMaxLength);

      if (props.hide_handle !== true && props.draggable !== "false") {
        itemWrapper.querySelector(".cf-item-handler").style.visibility = "hidden";
      }

      itemWrapper.querySelector(".cf-item-controls").style.display = "none";
    };

    if (props.panel_type !== "panel") {
      let modal = document.querySelector(
        `.modal[data-name="${props.setting_name}"][object_type="${props.object_type}"][object_id="${props.object_id}"][data-unique-identifier="${uniqueIdentifier}"]`
      );

      if (modal && panelVisibility == true) {
        $(modal).modal("show");
        $(modal).addClass("in").css("display", "block");
        $("body").addClass("modal-open");

        $(modal).parents(".modal").scrollTop(0);

        if (typeof props.callback === "function") {
          props.callback();
        }

        $(modal)
          .unbind("hidden.bs.modal")
          .bind("hidden.bs.modal", function (e) {
            setPanelVisibility(false);
          });

        modal.onclick = function (e) {
          if (e.target == modal || $(e.target).parent()[0] == modal) {
            if (
              $(modal).is(":visible") &&
              $(modal).find(".modal:visible").length == 0
            ) {
              setPanelVisibility(false);
            }
          }
        };
      } else if (modal) {
        $(modal).removeClass("in").css("display", "none");

        if ($(".modal.in").size() < 1) {
          $("body").removeClass("modal-open");
        }
      }
    }
  });

  useEffect(() => {
    let slideOutPanel = document.querySelector(
      `#cf-slide-out-panel-${props.object_id}`
    );

    if (slideOutPanel && panelVisibility == true) {
      slideOutPanel.addEventListener("wheel", (event) => {
        event.stopImmediatePropagation();
      });
    }

    if (panelVisibility == false) {
      if (typeof props.callbackOnClose === "function") {
        props.callbackOnClose();
      }
    }
  }, [panelVisibility]);

  useEffect(() => {
    if (
      contextData.selected_item_type == props.object_type &&
      contextData.selected_item_id == props.object_id &&
      props.object_type !== builder.selected().object_type
    ) {
      setPanelVisibility(true);
    }
  }, [contextData.selected_item_id]);

  const slideOutWrapper = () => {
    return (
      <VariantBuilderSlideOutPanel
        show={panelVisibility}
        setShow={setPanelVisibility}
        title={`${props.title} - Settings`}
        object_id={props.object_id}
        element_id={props.object?.element_id}
        {...props}
      >
        {props.children}
      </VariantBuilderSlideOutPanel>
    );
  };

  const modalWrapper = () => {
    return (
      <div
        className="modal"
        id={modalId}
        data-name={props.setting_name}
        object_type={props.object_type}
        object_id={props.object_id}
        data-unique-identifier={uniqueIdentifier}
        role="dialog"
        aria-labelledby="myModalLabel"
        data-backdrop="false"
        style={{
          background: "rgb(0, 0, 0, 0.5)",
          overflowY: "auto",
        }}
      >
        <div
          className="modal-dialog !mt-44 !w-[100%] !h-[100%] ml-auto mr-auto"
          role="document"
        >
          <div className="modal-content hard-center !w-[690px] bg-white dark:bg-slate-950 !shadow-2xl">
            <div className="modal-header !border-slate-200 dark:!border-slate-800">
              <button
                type="button"
                className="close dark:text-white"
                onClick={() => {
                  setPanelVisibility(false);
                }}
                aria-label="Close"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                  <path d="M12.2996 4.1001L4.09961 12.3001M12.2996 12.3001L4.09961 4.1001" strokeWidth="1.5" strokeLinecap="round"/>
                </svg>
              </button>
              <h4 className="text-2xl font-bold text-black dark:text-white whitespace-nowrap text-ellipsis overflow-hidden">
                {props.modalTitle
                  ? htmlToLabel(props.modalTitle)
                  : `Settings - ${htmlToLabel(props.title)}`}
              </h4>
            </div>
            <div className="modal-body">
              <div className="row hard-center">{props.children}</div>
            </div>
            <div className="modal-footer !border-slate-200 dark:!border-slate-800 mt-5">
              <button
                type="button"
                className="btn bg-white border-solid border-[1px] rounded-md !border-slate-200 text-black text-xl hover:bg-blue-100 hover:!border-blue-100 hover:text-blue-600"
                onClick={() => {
                  setPanelVisibility(false);
                }}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div
        className="row cf-item-v2"
        data-object-id={props.object_id}
        data-object_type={props.object_type}
        data-position={props.position}
        data-unique-identifier={uniqueIdentifier}
        data-nested-items-length={props.nested_items_length}
      >
        {props.draggable !== "false" && (
          <div className="cf-item-handler">
            <span
              className="glyphicon glyphicon-option-vertical"
              aria-hidden="true"
            ></span>
          </div>
        )}
        <div
          className={"flex-grow"}
          style={{ padding: "0px" }}
        >
          <div
            className={`cf-item-box my-3 text-xl py-2 pl-3 bg-white dark:bg-slate-950 border-solid border-[1px] ${
              props.highlight && contextData.selected_item_id == props.object_id
                ? "text-black dark:text-white border-black dark:border-white"
                : "text-slate-500 dark:text-slate-200 border-slate-200 dark:border-slate-700"
            } rounded-md`}
            data-object-id={props.object_id}
            onDoubleClick={() => {
              if (props.onEdit) {
                props.onEdit();
              } else if (props.children) {
                setPanelVisibility(true);
              }
            }}
          >
            {props.label_prepend && (
              <div className="cf-item-box-prepend cf-item-focusable border-solid border-0 !border-r-[1px] border-r-slate-200 dark:border-slate-700">
                {props.label_prepend}
              </div>
            )}

            <div className="cf-item-title cf-item-focusable">
              {props.label_icon && (
                <FontAwesomeIcon
                  className={`mt-4 ${
                    props.label_icon == "fa-solid fa-code-branch"
                      ? "rotate-180"
                      : ""
                  }`}
                  icon={props.label_icon}
                />
              )}
              {parsedItemLabel()}
            </div>

            <div className="cf-item-controls">
              <button
                title="Edit"
                className={`w-[25px] h-[25px] p-0 pt-1 bg-white hover:bg-slate-100 dark:bg-slate-950 dark:hover:bg-slate-800 cursor-pointer border-2 border-solid border-white hover:border-slate-100 dark:border-slate-950 dark:hover:border-slate-800 rounded-lg`}
                onClick={() => {
                  if (props.onEdit) {
                    props.onEdit();
                  } else if (props.children) {
                    setPanelVisibility(true);
                  }
                }}
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0869 5.21357L12.7869 7.91357M3.33691 14.6636L6.6114 14.0038C6.78524 13.9688 6.94485 13.8832 7.0702 13.7577L14.4005 6.42346C14.7519 6.07182 14.7517 5.50183 14.3999 5.15048L12.8471 3.59943C12.4955 3.24823 11.9258 3.24846 11.5745 3.59996L4.24349 10.935C4.11838 11.0602 4.03295 11.2195 3.99789 11.3929L3.33691 14.6636Z"
                    stroke={contextData.dark_mode ? "white" : "black"}
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>

              {hookToUse &&
                hookToUse.duplicate &&
                props.hide_duplicate !== true && (
                  <button
                    title="Duplicate"
                    className={`w-[25px] h-[25px] p-0 pt-1 bg-white hover:bg-slate-100 dark:bg-slate-950 dark:hover:bg-slate-800 cursor-pointer border-2 border-solid border-white hover:border-slate-100 dark:border-slate-950 dark:hover:border-slate-800 rounded-lg`}
                    onClick={() => {
                      if (actionButtonDisabled === false) {
                        setActionButtonDisabled(true);

                        hookToUse.duplicate(
                          props.object,
                          props.duplicate_payload
                        );

                        if (props.callbackOnDuplicate) {
                          props.callbackOnDuplicate(props.object);
                        }

                        setTimeout(function () {
                          setActionButtonDisabled(false);
                        }, 2000);
                      }
                    }}
                  >
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.25 9.84375L14.25 4.49994C14.25 3.25729 13.2426 2.24992 12 2.24994L6.65625 2.25M9.75 15.75L4.6875 15.75C3.75552 15.75 3 14.9945 3 14.0625L3 6.75C3 5.81802 3.75552 5.0625 4.6875 5.0625L9.75 5.0625C10.682 5.0625 11.4375 5.81802 11.4375 6.75L11.4375 14.0625C11.4375 14.9945 10.682 15.75 9.75 15.75Z"
                        stroke={contextData.dark_mode ? "white" : "black"}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  </button>
                )}

              {hookToUse && hookToUse.remove && props.hide_remove !== true && (
                <button
                  title="Remove"
                  className={`w-[25px] h-[25px] p-0 pt-1 bg-white hover:bg-slate-100 dark:bg-slate-950 dark:hover:bg-slate-800 cursor-pointer border-2 border-solid border-white hover:border-slate-100 dark:border-slate-950 dark:hover:border-slate-800 rounded-lg`}
                  onClick={() => {
                    hookToUse.remove(props.object);

                    if (props.callbackOnRemove) {
                      props.callbackOnRemove(props.object);
                    }
                  }}
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 4.63235H15M6.75 2.25H11.25M7.5 12.5735V7.80882M10.5 12.5735V7.80882M11.625 15.75H6.375C5.54657 15.75 4.875 15.0389 4.875 14.1618L4.53255 5.45953C4.5148 5.00837 4.85544 4.63235 5.2819 4.63235H12.7181C13.1446 4.63235 13.4852 5.00837 13.4674 5.45953L13.125 14.1618C13.125 15.0389 12.4534 15.75 11.625 15.75Z"
                      stroke={contextData.dark_mode ? "white" : "black"}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
        </div>
        
        {(props.nested_items_length || props.object_type == "fields") &&
          props.setShowNestedItems && (
            <div
              className="cf-item-children-toggle !pl-2"
              data-toggle="tooltip"
              data-placement="left"
              title={
                props.nested_tooltip ? props.nested_tooltip : "Add Sub-Item"
              }
              style={{
                cursor: "pointer",
                marginTop: "-5px",
                padding: "0px",
              }}
              onClick={() => {
                if (
                  props.create_nested_item_callback &&
                  props.nested_items_length == 0
                ) {
                  props.create_nested_item_callback();
                } else {
                  let showNestedItems = props.showNestedItems;
                  props.setShowNestedItems(
                    (showNestedItems) => !showNestedItems
                  );
                }
              }}
            >
              <div
                className="cf-item-children-toggle-icon text-slate-500 dark:text-slate-200"
                style={{ display: "none" }}
              >
                {props.create_nested_item_callback &&
                props.nested_items_length == 0 ? (
                  <FontAwesomeIcon icon="circle-plus" />
                ) : (
                  <FontAwesomeIcon
                    icon={props.showNestedItems ? "caret-down" : "caret-right"}
                  />
                )}
              </div>
            </div>
          )}

        {props.toggle_setting_name && (
          <div style={{ marginLeft: "10px" }}>
            <VariantBuilderSettingCheckbox
              setting_name={props.toggle_setting_name}
              value={props.toggle_value}
              disabled={props.toggle_disabled}
              object_id={props.object_id}
              object_type={props.object_type}
            />
          </div>
        )}

      </div>
      {props.children && panelVisibility == true && (
        <React.Fragment>
          {props.panel_type == "panel" ? slideOutWrapper() : modalWrapper()}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default VariantBuilderSettingItemV2;
