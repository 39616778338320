import React from "react";
import PropTypes from "prop-types";

const ElementImage = (props) => {
  let element = props.element;
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    props.cta,
    element
  );

  return (
    <React.Fragment>
      <div
        id={"element" + element.id + "-content"}
        className="element-content cf-text-element cf-image cf-merge-tag-allowed"
        dangerouslySetInnerHTML={{
          __html:
            props.builder == true
              ? "<div class='froala-editor'>" + element.html + "</div>"
              : element.html,
        }}
      ></div>
      <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
    </React.Fragment>
  );
};

const style = {
  stylesheet: (builder, device, cta, element) => {
    let options = { ...element.options };

    let boxShadow;

    if (options["box-shadow"] == "soft") {
      boxShadow = "0 5px 15px 0 rgb(179 179 179 / 78%)";
    }

    if (options["box-shadow"] == "hard") {
      boxShadow = "-5px 5px 0 0 rgb(0 0 0 / 10%)";
    }

    if (options["box-shadow"] == "custom") {
      boxShadow = "";
      boxShadow +=
        (element.options["box-shadow-horizontal-distance"]
          ? `${element.options["box-shadow-horizontal-distance"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["box-shadow-vertical-distance"]
          ? `${element.options["box-shadow-vertical-distance"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["box-shadow-blur-radius"]
          ? `${element.options["box-shadow-blur-radius"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["box-shadow-spread-radius"]
          ? `${element.options["box-shadow-spread-radius"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["box-shadow-color"]
          ? element.options["box-shadow-color"]
          : "rgba(0,0,0, 0.35)") + " ";
    }

    // prettier-ignore
    const desktopStyles = 
`#cta_${cta.id} .cf-element[data-element-id="${element.id}"] img {
  ${boxShadow ? 'box-shadow: ' + boxShadow + ' !important;' : ''}
}
`;

    // prettier-ignore
    const mobileStyles = 
`/* mobile styles */
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] img {
  ${element.options['image-width-mobile'] ? `width: ${element.options['image-width-mobile']}px !important;` : ''}
}`;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },
};

export default ElementImage;
