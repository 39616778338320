import React, { useState, useContext, useEffect } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import Field from "../fields/Field";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QuizExampleDesktop from "/app/assets/images/elements/quiz_example_desktop.svg";
import QuizExampleMobile from "/app/assets/images/elements/quiz_example_mobile.svg";
import arePropsEqual from "../../utils/arePropsEqual";

const ElementQuiz = (props) => {
  let element = props.element;
  let sorted_fields = [];
  let sorted_fields_without_subfields = Object.values(element.fields)
    .filter(
      (field) =>
        field.element_id == element.id &&
        field.toBeDeleted !== true &&
        !field.field_id
    )
    .sort((a, b) => a.position - b.position);

  Object.values(sorted_fields_without_subfields).forEach((field) => {
    sorted_fields.push(field);

    Object.values(element.fields)
      .filter(
        (sub_field) =>
          sub_field.element_id == element.id &&
          sub_field.toBeDeleted !== true &&
          sub_field.field_id == field.id
      )
      .sort((a, b) => a.position - b.position)
      .forEach((sub_field) => {
        sorted_fields.push(sub_field);
      });
  });

  const classes = style.classes(element, props.builder, props.device);
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    props.cta,
    element,
    props.variant,
    sorted_fields
  );

  const [currentQuestion, setCurrentQuestion] = useState(sorted_fields[0]);

  if (props.builder == true) {
    const [contextData, builder, setContextData] = useContext(
      VariantContextData
    );

    const updateQuestion = (question) => {
      if (!question) {
        return;
      }

      setCurrentQuestion(question);

      dispatchCustomEvent("focusItem", {
        parent_id: element.id,
        item_type: "fields",
        item_id: question.id
      });
    };

    useEffect(() => {
      const queryParameters = new URLSearchParams(window.location.search);
      const test_last_field = queryParameters.get("test_last_field");

      if (window.selected_object_id == element.id) {
        updateQuestion(
          test_last_field == "true"
            ? sorted_fields[sorted_fields.length - 1]
            : sorted_fields[0]
        );

        document
          .querySelectorAll(`#builder .cf-quiz-question-option`)
          .forEach((option) => {
            option.addEventListener("dblclick", () => openAnswerModal(option));
          });
      }
      return function cleanup() {
        document
          .querySelectorAll(`#builder .cf-quiz-question-option`)
          .forEach((input) => {
            input.removeEventListener("dblclick", openAnswerModal);
          });
      };
    }, [window.selected_object_id]);

    useEffect(() => {
      if (
        !currentQuestion ||
        !sorted_fields.find((f) => f.id == currentQuestion?.id)
      ) {
        updateQuestion(sorted_fields[0]);
      }
    }, [sorted_fields]);

    useEffect(() => {
      if (contextData.selected_item_parent_id == element.id) {
        let target_field = sorted_fields.find((field) => field.id == contextData.selected_item_id);

        if (target_field && target_field.id !== currentQuestion.id) {
          updateQuestion(target_field);
        }
      }
    }, [contextData.selected_item_id]);
  }

  const openAnswerModal = (option) => {
    let field_id = option.getAttribute("data-field-id");
    let field_option_id = option.getAttribute("data-field-option-id");

    let edit_button_question = document.querySelector(
      `.cf-item-box[data-object-id="${field_id}"] svg[data-icon="pen-to-square"]`
    );
    edit_button_question.dispatchEvent(
      new MouseEvent("click", { bubbles: true })
    );

    if (field_option_id) {
      let edit_button_answer = document.querySelector(
        `.cf-item-box[data-object-id="${field_option_id}"] svg[data-icon="pen-to-square"]`
      );

      edit_button_answer.dispatchEvent(
        new MouseEvent("click", { bubbles: true })
      );
    }
  };

  const currentQuestionIndex = () => {
    return sorted_fields.findIndex((f) => f.id == currentQuestion?.id);
  };

  const canHaveNextButton = () => {
    let has_next_field = currentQuestionIndex() + 1 <= sorted_fields.length;
    let not_finished = currentQuestionIndex() !== -1;
    let question;

    if (currentQuestion) {
      question = sorted_fields.find((e) => e.id == currentQuestion.id);
    }

    let currentCheck =
      question &&
      (question.multi_select == true || question.field_type == "range");

    let showing_loader =
      question &&
      question.options["show-loading-spinner"] == "true" &&
      question.field_type == "empty";

    if (element.options["hide-next-button"] == "true" || showing_loader) {
      return false;
    }

    return (
      ((has_next_field && not_finished) || currentCheck) && !showing_loader
    );
  };

  const canHaveBackButton = () => {
    let has_previous_field = currentQuestionIndex() > 0;
    let is_finished = currentQuestionIndex() == -1 && props.builder == true;
    let not_hidden = element.options["hide-back-button"] !== "true";
    let question;

    if (currentQuestion) {
      question = sorted_fields.find((e) => e.id == currentQuestion.id);
    }

    let showing_loader =
      question &&
      question.options["show-loading-spinner"] == "true" &&
      question.field_type == "empty";

    return (has_previous_field || is_finished) && not_hidden && !showing_loader;
  };

  const quizProgressInnerWidth = (position) => {
    return (100 * position) / sorted_fields_without_subfields.length;
  };

  const QuizProgressMessage = (props) => {
    const element = props.element;

    return (
      <React.Fragment>
        <div
          className="cf-progress-message"
          data-font={element.options["quiz-progress-text-font"]}
        >
          {element.options["quiz-progress-label"]}{" "}
          <span className="cf-progress-step-progress">
            {props.position}/{sorted_fields_without_subfields.length}
          </span>
        </div>
      </React.Fragment>
    );
  };

  const quizFormClasses = (field_item) => {
    let classes = "";

    if (
      field_item.options["quiz-answer-width"] &&
      field_item.options["quiz-answer-width"] !== "custom"
    ) {
      classes += ` ${field_item.options["quiz-answer-width"]}`;
    }

    if (
      !field_item.options["quiz-answer-field-size"] ||
      field_item.options["quiz-answer-field-size"] == "cf-field-regular"
    ) {
      classes += " cf-field-regular";
    }

    if (field_item.options["quiz-answer-field-size"] == "cf-field-large") {
      classes += " cf-field-large";
    }

    if (field_item.options["quiz-answer-field-size"] == "custom") {
      classes += " cf-field-custom";
    }

    classes +=
      " " +
      (field_item.options["quiz-answer-field-rounded"]
        ? field_item.options["quiz-answer-field-rounded"]
        : "cf-field-slightly-rounded");

    return classes;
  };

  const quizButtonClasses = () => {
    let classes = "element-content cf-button cf-button-primary cf-text-element";

    classes +=
      " " +
      (element.options["quiz-button-alignment"]
        ? element.options["quiz-button-alignment"]
        : "");

    classes +=
      " " +
      (element.options["quiz-button-size"]
        ? element.options["quiz-button-size"]
        : "cf-button-small");

    return classes;
  };

  return (
    <React.Fragment>
      <div className="element-content">
        <div
          className={classes}
          id={`cf-quiz-${element.id}`}
          data-font={
            element.options["collapse-heading-font-family"] ||
            props.variant.options["collapse-heading-font-family"]
          }
        >
          <input type="hidden" id={`cf-quiz-${element.id}-history`} />
          <input type="hidden" id={`cf-quiz-${element.id}-answers`} />
          <input
            type="hidden"
            id={`cf-quiz-${element.id}-current-question`}
            value={JSON.stringify(currentQuestion)}
            readOnly={true}
          />
          {sorted_fields.map((field_item, index) => {
            const sub_field_main_question_position =
              sorted_fields_without_subfields.findIndex(
                (f) => f.id == field_item.field_id
              ) + 1;
            const field_question_position =
              sorted_fields_without_subfields.findIndex(
                (f) => f.id == field_item.id
              ) + 1;

            const field_item_position = field_item.field_id
              ? sub_field_main_question_position
              : field_question_position;

            const labelImage = field_item.options["label-image"];
            const labelImagePlacement =
              props.device == "desktop"
                ? field_item.options["label-image-placement"] ||
                  props.variant.options["label-image-placement"]
                : field_item.options["label-image-placement-mobile"] ||
                  props.variant.options["label-image-placement-mobile"];

            return (
              <div
                className="cf-quiz-question"
                data-multi-select={field_item.multi_select || field_item.field_type == "checkbox"}
                data-multi-select-limit={
                  field_item.options["multi-select-limit"]
                }
                data-required={field_item.required}
                data-field-id={field_item.id}
                data-field-slug={field_item.slug}
                data-index={index}
                key={`quiz_question-${field_item.id}`}
                style={{
                  display:
                    currentQuestion?.id == field_item.id ? "block" : "none",
                }}
              >
                <div className="cf-quiz-answers-outer-wrapper">
                  {labelImage && (
                    <img
                      className="cf-quiz-label-image cf-quiz-image-left-right-above"
                      src={labelImage}
                      style={{
                        display:
                          labelImage &&
                          ["left", "right", "above"].includes(
                            labelImagePlacement
                          )
                            ? "block"
                            : "none",
                      }}
                    />
                  )}

                  <div className="cf-quiz-inner-wrapper">
                    {element.options["show-progress-bar"] !== "false" && (
                      <div className="cf-progress">
                        {element.options["quiz-progress-label-format"] ==
                          "line" && (
                          <QuizProgressMessage
                            element={element}
                            position={field_item_position}
                          />
                        )}

                        <div className="cf-progress-outer">
                          <div
                            className="cf-progress-inner"
                            data-progress-format={
                              element.options["quiz-progress-label-format"]
                            }
                            style={{
                              width: `${quizProgressInnerWidth(
                                field_item_position
                              )}%`,
                            }}
                          >
                            {element.options["quiz-progress-label-format"] ==
                              "box" && (
                              <QuizProgressMessage
                                element={element}
                                position={field_item_position}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="cf-quiz-content-answers-wrapper" data-field-id={field_item.id}>
                      <div>
                        <div
                          className="cf-quiz-question-label element-content cf-merge-tag-allowed"
                          dangerouslySetInnerHTML={{
                            __html: field_item.label,
                          }}
                        />
                        <div
                          className="cf-quiz-question-description cf-merge-tag-allowed"
                          dangerouslySetInnerHTML={{
                            __html: field_item.options["description"],
                          }}
                        />
                      </div>

                      {field_item.options["show-loading-spinner"] == "true" &&
                        field_item.field_type == "empty" &&
                        !field_item.options["loading-style"] && (
                          <span className="cf-loading-spinner"></span>
                        )}

                      {field_item.options["show-loading-spinner"] == "true" &&
                        field_item.field_type == "empty" &&
                        field_item.options["loading-style"] == "bar" && (
                          <span className="cf-loading-bar"></span>
                        )}

                      {labelImage && (
                        <img
                          className="cf-quiz-label-image cf-quiz-image-below"
                          src={labelImage}
                          style={{
                            display:
                              labelImage &&
                              !["left", "right", "above"].includes(
                                labelImagePlacement
                              )
                                ? "inline-block"
                                : "none",
                          }}
                        />
                      )}

                      <div className="cf-quiz-answers-wrapper">
                        {[
                          "text",
                          "dropdown",
                          "paragraph",
                          "radio",
                          "checkbox",
                          "number",
                          "range",
                        ].includes(field_item.field_type) && (
                          <div
                            className="cf-form-fields cf-quiz-question-option"
                            data-field-type={field_item.field_type}
                            data-field-id={field_item.id}
                            data-field-slug={field_item.slug}
                          >
                            <div
                              data-font={
                                field_item.options["quiz-answer-field-font"]
                              }
                              className={`cf-form-2 cf-form-theme-default ${quizFormClasses(
                                field_item
                              )}`}
                            >
                              <form>
                                <Field
                                  key={"field-" + field_item.id}
                                  field={field_item}
                                  hide_label={true}
                                  {...props}
                                />
                              </form>
                            </div>
                          </div>
                        )}

                        {[
                          "survey",
                          "image",
                          "color",
                          "emoji",
                          "icon",
                          "letter",
                        ].includes(field_item.field_type) &&
                          Object.values(field_item.field_options)
                            .filter(
                              (field_option) => field_option.toBeDeleted !== true
                            )
                            .sort((a, b) => a.position - b.position)
                            .map((option, i) => {
                              return (
                                <div
                                  key={`quiz_question_answer-${option.id}`}
                                  className="cf-quiz-question-option"
                                  data-field-type={field_item.field_type}
                                  data-field-id={field_item.id}
                                  data-field-slug={field_item.slug}
                                  data-field-option-id={option.id}
                                  data-field-value={option.value || option.label}
                                  data-position={option.position}
                                >
                                  {field_item.field_type === "image" && (
                                    <div className="cf-quiz-image-box">
                                      {option.image && option.image !== "none" ? (
                                        <img
                                          className="cf-quiz-option-image"
                                          src={option.image}
                                        ></img>
                                      ) : (
                                        <svg
                                          className="cf-quiz-option-image-svg cf-outer-center"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 525.5 525.5"
                                        >
                                          <path d="M324.5 212.7H203c-1.6 0-2.8 1.3-2.8 2.8V308c0 1.6 1.3 2.8 2.8 2.8h121.6c1.6 0 2.8-1.3 2.8-2.8v-92.5c0-1.6-1.3-2.8-2.9-2.8zm1.1 95.3c0 .6-.5 1.1-1.1 1.1H203c-.6 0-1.1-.5-1.1-1.1v-92.5c0-.6.5-1.1 1.1-1.1h121.6c.6 0 1.1.5 1.1 1.1V308z"></path>
                                          <path d="M210.4 299.5H240v.1s.1 0 .2-.1h75.2v-76.2h-105v76.2zm1.8-7.2l20-20c1.6-1.6 3.8-2.5 6.1-2.5s4.5.9 6.1 2.5l1.5 1.5 16.8 16.8c-12.9 3.3-20.7 6.3-22.8 7.2h-27.7v-5.5zm101.5-10.1c-20.1 1.7-36.7 4.8-49.1 7.9l-16.9-16.9 26.3-26.3c1.6-1.6 3.8-2.5 6.1-2.5s4.5.9 6.1 2.5l27.5 27.5v7.8zm-68.9 15.5c9.7-3.5 33.9-10.9 68.9-13.8v13.8h-68.9zm68.9-72.7v46.8l-26.2-26.2c-1.9-1.9-4.5-3-7.3-3s-5.4 1.1-7.3 3l-26.3 26.3-.9-.9c-1.9-1.9-4.5-3-7.3-3s-5.4 1.1-7.3 3l-18.8 18.8V225h101.4z"></path>
                                          <path d="M232.8 254c4.6 0 8.3-3.7 8.3-8.3s-3.7-8.3-8.3-8.3-8.3 3.7-8.3 8.3 3.7 8.3 8.3 8.3zm0-14.9c3.6 0 6.6 2.9 6.6 6.6s-2.9 6.6-6.6 6.6-6.6-2.9-6.6-6.6 3-6.6 6.6-6.6z"></path>
                                        </svg>
                                      )}
                                    </div>
                                  )}

                                  {["color", "emoji", "icon", "letter"].includes(
                                    field_item.field_type
                                  ) && (
                                    <div
                                      className="cf-quiz-visual-box"
                                      data-visual-type={field_item.field_type}
                                    >
                                      {field_item.field_type === "color" && (
                                        <div
                                          className="cf-quiz-option-color"
                                          style={{
                                            backgroundColor: option.color,
                                            color: option.color,
                                          }}
                                        >
                                          .
                                        </div>
                                      )}

                                      {field_item.field_type === "emoji" && (
                                        <div className="cf-quiz-option-emoji">
                                          {option.emoji || "😎"}
                                        </div>
                                      )}

                                      {field_item.field_type === "icon" && (
                                        <div className="cf-quiz-option-icon">
                                          <FontAwesomeIcon
                                            icon={option.icon || "fas fa-archive"}
                                          />
                                        </div>
                                      )}

                                      {field_item.field_type === "letter" &&
                                        option.letter && (
                                          <span className="cf-quiz-option-letter">
                                            {option.letter}
                                          </span>
                                        )}
                                    </div>
                                  )}

                                  {option.label && (
                                    <div className="cf-quiz-option-label">
                                      {option.label}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                      </div>
                    </div>

                    <div
                      className="cf-quiz-buttons-wrapper"
                      data-font={element.options["quiz-button-font"]}
                    >
                      <div
                        id={`cf-back-button-quiz-${element.id}`}
                        className={quizButtonClasses() + " cf-quiz-back-button"}
                        style={{
                          display: canHaveBackButton() ? "block" : "none",
                        }}
                        data-field-id={field_item.id}
                      >
                        <p className="cf-label">
                          {element.options["back-button-label"] || "← Back"}
                        </p>
                      </div>

                      <div
                        id={`cf-next-button-quiz-${element.id}`}
                        className={quizButtonClasses() + " cf-quiz-next-button"}
                        style={{
                          display: canHaveNextButton() ? "block" : "none",
                        }}
                        data-field-id={field_item.id}
                      >
                        <p className="cf-label">
                          {element.options["next-button-label"] || "Next →"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {currentQuestionIndex() == -1 && props.builder == true && (
            <div className="fallback-empty-example">
              {props.device == "desktop" && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={QuizExampleDesktop} />
                </div>
              )}

              {props.device == "mobile" && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={QuizExampleMobile} />
                </div>
              )}
            </div>
          )}

          <div
            id="cf-quiz-confirmation-type"
            style={{
              display:
                currentQuestionIndex() == -1 && props.builder !== true
                  ? "block"
                  : "none",
            }}
          >
            {element.options["confirmation-type"] == "message" && (
              <p>{element.options["confirmation"] || "Thanks!"}</p>
            )}
          </div>

          <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
        </div>
      </div>
    </React.Fragment>
  );
};

const style = {
  classes: (element, builder, device) => {
    let options = element.options;
    let classes = "element-content cf-quiz-element ";
    let alignmentClass = "cf-outer-center";

    if (device == "mobile") {
      if (options["cf-alignment-mobile"]) {
        if (options["cf-alignment-mobile"].indexOf("left") > -1) {
          alignmentClass = "cf-outer-left";
        } else if (options["cf-alignment-mobile"].indexOf("center") > -1) {
          alignmentClass = "cf-outer-center";
        } else if (options["cf-alignment-mobile"].indexOf("right") > -1) {
          alignmentClass = "cf-outer-right";
        }
      }
    } else {
      if (options["cf-alignment"]) {
        if (options["cf-alignment"].indexOf("left") > -1) {
          alignmentClass = "cf-outer-left";
        } else if (options["cf-alignment"].indexOf("center") > -1) {
          alignmentClass = "cf-outer-center";
        } else if (options["cf-alignment"].indexOf("right") > -1) {
          alignmentClass = "cf-outer-right";
        }
      }
    }

    classes += " " + alignmentClass;

    return classes;
  },
  stylesheet: (builder, device, cta, element, variant, sorted_fields) => {
    let options = { ...element.options };

    let quizProgressLabelFormat = options["quiz-progress-label-format"];
    let quizProgressOuterWidth =
      options["quiz-progress-outer-width"] || undefined;

    let quizProgressOuterRadius = "";
    if (options["quiz-progress-outer-radius"] == "cf-not-rounded") {
      quizProgressOuterRadius = "0px";
    }
    if (options["quiz-progress-outer-radius"] == "cf-slightly-rounded") {
      quizProgressOuterRadius = "4px";
    }
    if (options["quiz-progress-outer-radius"] == "cf-fully-rounded") {
      quizProgressOuterRadius = "100px";
    }
    if (options["quiz-progress-outer-radius"] == "custom") {
      quizProgressOuterRadius = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          quizProgressOuterRadius += `${
            _.isNil(options["quiz-progress-outer-radius-" + side]) == false
              ? options["quiz-progress-outer-radius-" + side]
              : "4"
          }px `;
        }
      );
    }

    let quizMaxWidthElement = "";
    if (
      options["quiz-max-width-style"] == "custom" &&
      options["quiz-max-width-value"]
    ) {
      quizMaxWidthElement = `${options["quiz-max-width-value"]}px`;
    } else if (options["quiz-max-width-style"] == "full") {
      quizMaxWidthElement = "100%";
    }

    // prettier-ignore

    let quizButtonBorderRadius = "";
    if (options["quiz-button-radius"] == "cf-not-rounded") {
      quizButtonBorderRadius = "0px";
    }
    if (options["quiz-button-radius"] == "cf-slightly-rounded") {
      quizButtonBorderRadius = "4px";
    }
    if (options["quiz-button-radius"] == "cf-fully-rounded") {
      quizButtonBorderRadius = "100px";
    }
    if (options["quiz-button-radius"] == "custom") {
      quizButtonBorderRadius = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          quizButtonBorderRadius += `${
            _.isNil(options["quiz-button-radius-" + side]) == false
              ? options["quiz-button-radius-" + side]
              : "4"
          }px `;
        }
      );
    }

    let quizBackButtonBorderRadius = "";
    if (options["quiz-back-button-radius"] == "cf-not-rounded") {
      quizBackButtonBorderRadius = "0px";
    }
    if (options["quiz-back-button-radius"] == "cf-slightly-rounded") {
      quizBackButtonBorderRadius = "4px";
    }
    if (options["quiz-back-button-radius"] == "cf-fully-rounded") {
      quizBackButtonBorderRadius = "100px";
    }
    if (options["quiz-back-button-radius"] == "custom") {
      quizBackButtonBorderRadius = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          quizBackButtonBorderRadius += `${
            _.isNil(options["quiz-back-button-radius-" + side]) == false
              ? options["quiz-back-button-radius-" + side]
              : "4"
          }px `;
        }
      );
    }

    let quizButtonBoxShadow;
    let quizButtonBoxShadowHover;

    if (options["quiz-button-shadow"] == "None") {
      quizButtonBoxShadow = "none";
    }

    if (options["quiz-button-shadow"] == "soft") {
      quizButtonBoxShadow = "0 5px 15px 0 rgb(179 179 179 / 78%)";
    }

    if (options["quiz-button-shadow"] == "hard") {
      quizButtonBoxShadow = "-5px 5px 0 0 rgb(0 0 0 / 10%)";
    }

    let quizButtonFont;
    if (
      options["quiz-button-font"] &&
      options["quiz-button-font"] !== "theme"
    ) {
      quizButtonFont = options["quiz-button-font"];
    }

    let quizButtonFontWeight;
    if (
      options["quiz-button-font-weight"] &&
      options["quiz-button-font-weight"] !== "theme"
    ) {
      quizButtonFontWeight = options["quiz-button-font-weight"];
    }

    let quizButtonFontSize;
    if (options["quiz-button-font-size"]) {
      quizButtonFontSize = options["quiz-button-font-size"];
    }

    if (options["quiz-button-shadow"] == "custom") {
      quizButtonBoxShadow = "";
      quizButtonBoxShadow +=
        (element.options["quiz-button-shadow-horizontal-distance"]
          ? `${element.options["quiz-button-shadow-horizontal-distance"]}px`
          : "0px") + " ";

      quizButtonBoxShadow +=
        (element.options["quiz-button-shadow-vertical-distance"]
          ? `${element.options["quiz-button-shadow-vertical-distance"]}px`
          : "0px") + " ";

      quizButtonBoxShadow +=
        (element.options["quiz-button-shadow-blur-radius"]
          ? `${element.options["quiz-button-shadow-blur-radius"]}px`
          : "0px") + " ";

      quizButtonBoxShadow +=
        (element.options["quiz-button-shadow-spread-radius"]
          ? `${element.options["quiz-button-shadow-spread-radius"]}px`
          : "0px") + " ";

      quizButtonBoxShadow +=
        (element.options["quiz-button-shadow-color"]
          ? element.options["quiz-button-shadow-color"]
          : "rgba(0,0,0, 0.35)") + " ";

      quizButtonBoxShadowHover =
        quizButtonBoxShadow +
        (element.options["quiz-button-hover-shadow-color"]
          ? element.options["quiz-button-hover-shadow-color"]
          : element.options["quiz-button-shadow-color"]
          ? element.options["quiz-button-shadow-color"]
          : "rgba(0,0,0, 0.35)");

      quizButtonBoxShadow +=
        element.options["quiz-button-shadow-inset"] == "true" ? "inset" : "";
      quizButtonBoxShadowHover +=
        element.options["quiz-button-shadow-inset"] == "true" ? "inset" : "";
    }

    let quizQuestionFontSize;
    if (
      options["quiz-question-font-size"] &&
      options["quiz-question-font-size"] !== "theme"
    ) {
      quizQuestionFontSize = options["quiz-question-font-size"];
    }

    let quizQuestionLineHeight;
    if (options["quiz-question-font-line-height"]) {
      quizQuestionLineHeight = options["quiz-question-font-line-height"];
    }

    let quizQuestionFontFamily;
    if (
      options["quiz-question-font-family"] &&
      options["quiz-question-font-family"] !== "theme"
    ) {
      quizQuestionFontFamily = options["quiz-question-font-family"];
    }

    let quizQuestionFontWeight;
    if (
      options["quiz-question-font-weight"] &&
      options["quiz-question-font-weight"] !== "theme"
    ) {
      quizQuestionFontWeight = options["quiz-question-font-weight"];
    }

    let quizQuestionFontColor;
    if (
      options["quiz-question-font-color"] &&
      options["quiz-question-font-color"] !== "theme"
    ) {
      quizQuestionFontColor = options["quiz-question-font-color"];
    }

    let quizDescriptionFontSize;
    if (
      options["quiz-description-font-size"] &&
      options["quiz-description-font-size"] !== "theme"
    ) {
      quizDescriptionFontSize = options["quiz-description-font-size"];
    }

    let quizDescriptionLineHeight;
    if (options["quiz-description-font-line-height"]) {
      quizDescriptionLineHeight = options["quiz-description-font-line-height"];
    }

    let quizDescriptionFontFamily;
    if (
      options["quiz-description-font-family"] &&
      options["quiz-description-font-family"] !== "theme"
    ) {
      quizDescriptionFontFamily = options["quiz-description-font-family"];
    }

    let quizDescriptionFontWeight;
    if (
      options["quiz-description-font-weight"] &&
      options["quiz-description-font-weight"] !== "theme"
    ) {
      quizDescriptionFontWeight = options["quiz-description-font-weight"];
    }

    let quizDescriptionFontColor;
    if (
      options["quiz-description-font-color"] &&
      options["quiz-description-font-color"] !== "theme"
    ) {
      quizDescriptionFontColor = options["quiz-description-font-color"];
    }

    let quizQuestionDistanceFromProgress =
      options["quiz-question-distance-from-progress"];

    let quizContentAlignment = "";
    let quizContentAlignmentMargin = "";
    switch (options["cf-content-alignment"]) {
      case "cf-left":
        quizContentAlignmentMargin = "margin-right: auto;";
        quizContentAlignment =
          "text-align: left !important; justify-content: flex-start !important; align-items: start; align-content: start; margin-left: 0px;";
        break;
      case "cf-center":
        quizContentAlignmentMargin = "margin-right: auto; margin-left: auto;";
        quizContentAlignment =
          "text-align: center !important; justify-content: center !important; align-items: center; align-content: center;";
        break;
      case "cf-right":
        quizContentAlignmentMargin = "margin-left: auto;";
        quizContentAlignment =
          "text-align: right !important; justify-content: flex-end !important; align-items: end; align-content: end; margin-right: 0px;";
        break;
    }

    let quizQuestionSpacing =
      options["quiz-question-spacing"] ||
      variant.options["quiz-question-spacing"] ||
      undefined;

    let quizButtonWidth;
    if (options["quiz-button-width"] == "cf-button-slim") {
      quizButtonWidth = "min-width: fit-content !important;"
    } else if (options["quiz-button-width"] == "cf-button-mid") {
      quizButtonWidth = "min-width: 250px !important;"
    } else if (options["quiz-button-width"] == "cf-full-width") {
      quizButtonWidth = "min-width: 100% !important;"
    } else if (options["quiz-button-width"] == "custom") {
      quizButtonWidth = "min-width: " + options["quiz-button-width-value"] + "px !important;"
    }

    // prettier-ignore
    let desktopStyles =
    `#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-element {
      ${quizMaxWidthElement ? `max-width: ${quizMaxWidthElement};` : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress,
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question,
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-answers-wrapper,
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-answers-wrapper .cf-form-2,
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-buttons-wrapper {
      ${quizContentAlignment ? quizContentAlignment : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-description, #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-description * {
      ${quizDescriptionFontSize ? `font-size: ${quizDescriptionFontSize}px;` : ''}
      ${quizDescriptionFontWeight ? `font-weight: ${quizDescriptionFontWeight};` : ''}
      ${quizDescriptionFontColor ? `color: ${quizDescriptionFontColor};` : ''}
      ${quizDescriptionFontFamily && quizDescriptionFontFamily !== 'inherit' ? `font-family: ${quizDescriptionFontFamily}, sans-serif;` : ''}
      ${quizDescriptionLineHeight ? `line-height: ${quizDescriptionLineHeight}px;` : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-description,
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-label {
      ${quizQuestionSpacing ? `margin-bottom: ${quizQuestionSpacing}px;` : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-answers-wrapper {
      ${quizQuestionSpacing ? `margin: ${quizQuestionSpacing}px 0px;` : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-label, #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-label * {
      ${quizQuestionFontSize ? `font-size: ${quizQuestionFontSize}px;` : ''}
      ${quizQuestionFontWeight ? `font-weight: ${quizQuestionFontWeight};` : ''}
      ${quizQuestionFontColor ? `color: ${quizQuestionFontColor};` : ''}
      ${quizQuestionFontFamily && quizQuestionFontFamily !== 'inherit' ? `font-family: ${quizQuestionFontFamily}, sans-serif;` : ''}
      ${quizQuestionLineHeight ? `line-height: ${quizQuestionLineHeight}px;` : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress {
      ${quizQuestionDistanceFromProgress ? `margin-bottom: ${quizQuestionDistanceFromProgress}px;` : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-button {
      ${options["quiz-button-color"] ? 'background: ' + options["quiz-button-color"] + '!important;' : ''}
      ${options["quiz-button-text-color"] ? 'color: ' + options["quiz-button-text-color"] + ' !important;' : ''}
      ${quizButtonWidth ? quizButtonWidth : ''}
      ${options["quiz-button-size"] == 'custom' && options["quiz-button-size-value"] ? 'padding: ' + options["quiz-button-size-value"] + 'px 20px !important;' : ''}
      ${options["quiz-button-border-style"] ? 'border-style: ' + options["quiz-button-border-style"] + ' !important;' : ''}
      ${options["quiz-button-border-style"] !== "" && _.isNil(options["quiz-button-border-width"]) == false ? 'border-width: ' + options["quiz-button-border-width"] + 'px !important;' : ''}
      ${options["quiz-button-border-style"] !== "" && _.isNil(options["quiz-button-border-width-left"]) == false ? 'border-left-width: ' + options["quiz-button-border-width-left"] + 'px !important;' : _.isNil(options["quiz-button-border-width"]) == false ? 'border-left-width: ' + options["quiz-button-border-width"] + 'px !important;' : ''}
      ${options["quiz-button-border-style"] !== "" && _.isNil(options["quiz-button-border-width-right"]) == false ? 'border-right-width: ' + options["quiz-button-border-width-right"] + 'px !important;' : _.isNil(options["quiz-button-border-width"]) == false ? 'border-right-width: ' + options["quiz-button-border-width"] + 'px !important;' : ''}
      ${options["quiz-button-border-style"] !== "" && _.isNil(options["quiz-button-border-width-top"]) == false ? 'border-top-width: ' + options["quiz-button-border-width-top"] + 'px !important;' : _.isNil(options["quiz-button-border-width"]) == false ? 'border-top-width: ' + options["quiz-button-border-width"] + 'px !important;' : ''}
      ${options["quiz-button-border-style"] !== "" && _.isNil(options["quiz-button-border-width-bottom"]) == false ? 'border-bottom-width: ' + options["quiz-button-border-width-bottom"] + 'px !important;' : _.isNil(options["quiz-button-border-width"]) == false ? 'border-bottom-width: ' + options["quiz-button-border-width"] + 'px !important;' : ''}
      ${options["quiz-button-border-style"] !== "" && options["quiz-button-border-color"] ? 'border-color: ' + options["quiz-button-border-color"] + ' !important;' : options["quiz-button-color"] ? 'border-color: ' + options["quiz-button-color"] + ' !important;' : ''}
      ${quizButtonBorderRadius ? 'border-radius: ' + quizButtonBorderRadius + ' !important;' : ''}
      ${quizButtonBoxShadow ? 'box-shadow: ' + quizButtonBoxShadow + ' !important;' : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-button:hover, #cta_${cta.id} .cf-button:active {
      ${options["quiz-button–hover-color"] ? 'background: ' + options["quiz-button–hover-color"] + ' !important;' : options["quiz-button-color"] ? 'background: ' + options["quiz-button-color"] + ' !important;' : ''}
      ${options["quiz-button–hover-text-color"] ? 'color: ' + options["quiz-button–hover-text-color"] + ' !important;' : options["quiz-button-text-color"] ? 'color: ' + options["quiz-button-text-color"] + ' !important;' : ''}
      ${options["quiz-button-hover-border-color"] ? 'border-color: ' + options["quiz-button-hover-border-color"] + ' !important;' : options["quiz-button-border-color"] ? 'border-color: ' +  options["quiz-button-border-color"] + ' !important;' : ''}
      ${quizButtonBoxShadowHover ? 'box-shadow: ' + quizButtonBoxShadowHover + ' !important;' : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-button p {
      ${quizButtonFont ? `font-family: ${quizButtonFont}, sans-serif !important;` : ''}
      ${quizButtonFontWeight ? `font-weight: ${quizButtonFontWeight} !important;` : ''}
      ${quizButtonFontSize ? `font-size: ${quizButtonFontSize}px !important;` : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-back-button {
      ${options["quiz-back-button-color"] ? `background: ${options["quiz-back-button-color"]} !important;` : ''}
      ${options["quiz-back-button-text-color"] ? `color: ${options["quiz-back-button-text-color"]} !important;` : ''}
      ${options["quiz-back-button-border-style"] ? 'border-style: ' + options["quiz-back-button-border-style"] + ' !important;' : ''}
      ${options["quiz-back-button-border-style"] !== "" && _.isNil(options["quiz-back-button-border-width"]) == false ? 'border-width: ' + options["quiz-back-button-border-width"] + 'px !important;' : ''}
      ${options["quiz-back-button-border-style"] !== "" && _.isNil(options["quiz-back-button-border-width-left"]) == false ? 'border-left-width: ' + options["quiz-back-button-border-width-left"] + 'px !important;' : _.isNil(options["quiz-button-border-width"]) == false ? 'border-left-width: ' + options["quiz-button-border-width"] + 'px !important;' : ''}
      ${options["quiz-back-button-border-style"] !== "" && _.isNil(options["quiz-back-button-border-width-right"]) == false ? 'border-right-width: ' + options["quiz-back-button-border-width-right"] + 'px !important;' : _.isNil(options["quiz-back-button-border-width"]) == false ? 'border-right-width: ' + options["quiz-back-button-border-width"] + 'px !important;' : ''}
      ${options["quiz-back-button-border-style"] !== "" && _.isNil(options["quiz-back-button-border-width-top"]) == false ? 'border-top-width: ' + options["quiz-back-button-border-width-top"] + 'px !important;' : _.isNil(options["quiz-back-button-border-width"]) == false ? 'border-top-width: ' + options["quiz-back-button-border-width"] + 'px !important;' : ''}
      ${options["quiz-back-button-border-style"] !== "" && _.isNil(options["quiz-back-button-border-width-bottom"]) == false ? 'border-bottom-width: ' + options["quiz-back-button-border-width-bottom"] + 'px !important;' : _.isNil(options["quiz-back-button-border-width"]) == false ? 'border-bottom-width: ' + options["quiz-back-button-border-width"] + 'px !important;' : ''}
      ${options["quiz-back-button-border-style"] !== "" && options["quiz-back-button-border-color"] ? 'border-color: ' + options["quiz-back-button-border-color"] + ' !important;' : options["quiz-back-button-color"] ? 'border-color: ' + options["quiz-back-button-color"] + ' !important;' : ''}
      ${quizBackButtonBorderRadius ? 'border-radius: ' + quizBackButtonBorderRadius + ' !important;' : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-back-button:hover, #cta_${cta.id} .cf-quiz-back-button:active {
      ${options["quiz-back-button–hover-color"] ? `background: ${options["quiz-back-button–hover-color"]} !important;` : options["quiz-button-color"] ? `background: ${options["quiz-button-color"]} !important;` : ''}
      ${options["quiz-back-button–hover-text-color"] ? `color: ${options["quiz-back-button–hover-text-color"]} !important;` : options["quiz-button-text-color"] ? `color: ${options["quiz-button-text-color"]} !important;` : ''}
      ${options["quiz-back-button-hover-border-color"] ? 'border-color: ' + options["quiz-back-button-hover-border-color"] + ' !important;' : options["quiz-back-button-border-color"] ? 'border-color: ' +  options["quiz-back-button-border-color"] + ' !important;' : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default .noUi-connect {
      ${_.isNil(options["quiz-field-border-color"]) == false ? `background: ${options["quiz-field-border-color"]} !important;` : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question .cf-progress {
      ${quizProgressOuterRadius ? `border-radius: ${quizProgressOuterRadius} !important;` : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question .cf-progress-inner {
      ${options["quiz-progress-inner-background-color"] ? `background: ${options["quiz-progress-inner-background-color"]} !important;` : variant.options["quiz-progress-inner-background-color"] ? "" : "background: #0D42E4;"}
      ${quizProgressLabelFormat !== "box" && options['quiz-progress-height'] ? `min-height: ${options['quiz-progress-height']}px !important;` : ''}
      ${quizProgressLabelFormat == "box" && options['quiz-progress-inner-padding'] ? `padding: ${options['quiz-progress-inner-padding']}px !important;` : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message,
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message h1,
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message h2,
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message h3,
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message h4,
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message h5,
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message p,
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message span {
      ${options["quiz-progress-text-font"] ? `font-family: ${options["quiz-progress-text-font"]}, Helvetica, Arial, sans-serif !important;` : ''}
      ${options['quiz-progress-text-font-weight'] ? `font-weight: ${options['quiz-progress-text-font-weight']} !important;` : ''}
      ${options["quiz-progress-text-color"] ? `color: ${options["quiz-progress-text-color"]} !important;` : ''}
      ${options["quiz-progress-text-size"] ? `font-size: ${options["quiz-progress-text-size"]}px !important;` : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question .cf-progress-outer {
      ${quizProgressOuterRadius ? `border-radius: ${quizProgressOuterRadius} !important;` : ''}
      ${_.isNil(options["quiz-progress-border-width-left"]) == false ? `border-left-width: ${options["quiz-progress-border-width-left"]}px !important;` : ''}
      ${_.isNil(options["quiz-progress-border-width-right"]) == false ? `border-right-width: ${options["quiz-progress-border-width-right"]}px !important;` : ''}
      ${_.isNil(options["quiz-progress-border-width-bottom"]) == false ? `border-bottom-width: ${options["quiz-progress-border-width-bottom"]}px !important;` : ''}
      ${_.isNil(options["quiz-progress-border-width-top"]) == false ? `border-top-width: ${options["quiz-progress-border-width-top"]}px !important;` : ''}
      ${options["quiz-progress-outer-background-color"] ? `background: ${options["quiz-progress-outer-background-color"]} !important;` : variant.options["progress-outer-background-color"] ? variant.options["progress-outer-background-color"] : "background: #fff;"}
      ${options["quiz-progress-border-style"] ? `border-style: ${options["quiz-progress-border-style"]} !important;` : ''}
      ${options["quiz-progress-border-color"] ? `border-color: ${options["quiz-progress-border-color"]} !important;` : variant.options["progress-outer-border-color"] ? variant.options["progress-outer-border-color"] : "border-color: #000;"}
      ${quizProgressOuterWidth ? `max-width: ${quizProgressOuterWidth}px !important;` : ' max-width: 400px;'}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question .cf-progress-message {
      ${quizProgressLabelFormat == "line" ? 'flex: 0 0 100%; color: black;' : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-buttons-wrapper .cf-full-width {
      ${element.options["quiz-button-width"] == "cf-full-width" || !element.options["quiz-button-width"] ? 'min-width: fit-content !important;' : ''}
    }
    `;

    // prettier-ignore
    const optionBorderStyles = (field_item, show = true) => {
      let quizOptionBorderRadius = "";

      if (field_item.options["quiz-option-border-radius"] == "cf-not-rounded") {
        quizOptionBorderRadius = "0px";
      } else if (field_item.options["quiz-option-border-radius"] == "cf-slightly-rounded") {
        quizOptionBorderRadius = "4px";
      } else if (field_item.options["quiz-option-border-radius"] == "cf-fully-rounded") {
        quizOptionBorderRadius = "100px";
      } else if (field_item.options["quiz-option-border-radius"] == "custom") {
        quizOptionBorderRadius = "";
        ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
          (side) => {
            quizOptionBorderRadius += `${
              _.isNil(field_item.options["quiz-option-border-radius-" + side]) == false
                ? field_item.options["quiz-option-border-radius-" + side]
                : "4"
            }px `;
          }
        );
      } else if (variant.options["quiz-option-border-radius"] == "cf-not-rounded") {
        quizOptionBorderRadius = "0px";
      } else if (variant.options["quiz-option-border-radius"] == "cf-slightly-rounded") {
        quizOptionBorderRadius = "4px";
      } else if (variant.options["quiz-option-border-radius"] == "cf-fully-rounded") {
        quizOptionBorderRadius = "100px";
      } else if (variant.options["quiz-option-border-radius"] == "custom") {
        quizOptionBorderRadius = "";
        ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
          (side) => {
            quizOptionBorderRadius += `${
              _.isNil(variant.options["quiz-option-border-radius-" + side]) == false
                ? variant.options["quiz-option-border-radius-" + side]
                : "4"
            }px `;
          }
        );
      } else {
        quizOptionBorderRadius = "4px";
      }

      let borderStyle = field_item.options["quiz-option-border-style"] || variant.options["quiz-option-border-style"] || 'solid';
      let optionBorderWidth = field_item.options["quiz-option-border-width"] || variant.options["quiz-option-border-width"] || undefined;
      let optionBorderWidthLeft = field_item.options["quiz-option-border-width-left"] || variant.options["quiz-option-border-width-left"] || undefined;
      let optionBorderWidthRight = field_item.options["quiz-option-border-width-right"] || variant.options["quiz-option-border-width-right"] || undefined;
      let optionBorderWidthTop = field_item.options["quiz-option-border-width-top"] || variant.options["quiz-option-border-width-top"] || undefined;
      let optionBorderWidthBottom = field_item.options["quiz-option-border-width-bottom"] || variant.options["quiz-option-border-width-bottom"] || undefined;
      let optionBorderColor = field_item.options["quiz-option-border-color"] || variant.options["quiz-option-border-color"] || undefined;

      let optionBorderStyles = `
        ${show ? `border-style: ${borderStyle};` : 'border-style: none;'}
        ${_.isNil(optionBorderWidth) == false ? `border-width: ${optionBorderWidth}px;` : ''}
        ${_.isNil(optionBorderWidthLeft) == false ? `border-left-width: ${optionBorderWidthLeft}px;` : ''}
        ${_.isNil(optionBorderWidthRight) == false ? `border-right-width: ${optionBorderWidthRight}px;` : ''}
        ${_.isNil(optionBorderWidthTop) == false ? `border-top-width: ${optionBorderWidthTop}px;` : ''}
        ${_.isNil(optionBorderWidthBottom) == false ? `border-bottom-width: ${optionBorderWidthBottom}px;` : ''}
        ${_.isNil(optionBorderColor) == false ? `border-color: ${optionBorderColor};` : ''}
        ${quizOptionBorderRadius ? `border-radius: ${quizOptionBorderRadius};` : ''}
      `
      return optionBorderStyles
    }

    // prettier-ignore
    const hoverOptionStyles = (field_item, show) => {
      let hoverOptionStyles;

      let optionBorderColor = field_item.options["quiz-hover-option-border-color"] || variant.options["quiz-hover-option-border-color"] || undefined;
      let optionBackgroundColor = field_item.options["quiz-hover-option-background-color"] || variant.options["quiz-hover-option-background-color"] || undefined;

      if (show) {
        hoverOptionStyles = `
          ${_.isNil(optionBorderColor) == false ? `border-color: ${optionBorderColor} !important;` : ''}
          ${_.isNil(optionBackgroundColor) == false ? `background-color: ${optionBackgroundColor} !important;` : ''}
        `
      }

      return hoverOptionStyles
    }

    // prettier-ignore
    const selectedOptionBorderStyles = (field_item, show = true) => {
      let selectedOptionBorderStyles = '';
      let optionSelectedStyle = field_item.options["quiz-selected-option-style"] || variant.options["quiz-selected-option-style"] || 'transparent';

      if (optionSelectedStyle == "transparent") {
        selectedOptionBorderStyles = `
          opacity: 0.5;
        `
      } else {
        let borderStyle = field_item.options["quiz-selected-option-border-style"] || variant.options["quiz-selected-option-border-style"] || field_item.options["quiz-option-border-style"] || variant.options["quiz-option-border-style"] || 'solid';
        let optionBorderWidth = field_item.options["quiz-selected-option-border-width"] || variant.options["quiz-selected-option-border-width"] || undefined;
        let optionBorderWidthLeft = field_item.options["quiz-selected-option-border-width-left"] || variant.options["quiz-selected-option-border-width-left"] || undefined;
        let optionBorderWidthRight = field_item.options["quiz-selected-option-border-width-right"] || variant.options["quiz-selected-option-border-width-right"] || undefined;
        let optionBorderWidthTop = field_item.options["quiz-selected-option-border-width-top"] || variant.options["quiz-selected-option-border-width-top"] || undefined;
        let optionBorderWidthBottom = field_item.options["quiz-selected-option-border-width-bottom"] || variant.options["quiz-selected-option-border-width-bottom"] || undefined;
        let optionBorderColor = field_item.options["quiz-selected-option-border-color"] || variant.options["quiz-selected-option-border-color"] || undefined;

        selectedOptionBorderStyles = `
          ${show ? `border-style: ${borderStyle} !important;` : 'border-style: none !important;'}
          ${_.isNil(optionBorderWidth) == false ? `border-width: ${optionBorderWidth}px;` : ''}
          ${_.isNil(optionBorderWidthLeft) == false ? `border-left-width: ${optionBorderWidthLeft}px;` : ''}
          ${_.isNil(optionBorderWidthRight) == false ? `border-right-width: ${optionBorderWidthRight}px;` : ''}
          ${_.isNil(optionBorderWidthTop) == false ? `border-top-width: ${optionBorderWidthTop}px;` : ''}
          ${_.isNil(optionBorderWidthBottom) == false ? `border-bottom-width: ${optionBorderWidthBottom}px;` : ''}
          ${_.isNil(optionBorderColor) == false ? `border-color: ${optionBorderColor};` : ''}
        `
      }

      return selectedOptionBorderStyles
    }

    // INDIVIDUAL OPTIONS STYLES
    // prettier-ignore
    Object.values(sorted_fields).forEach((field_item) => {
      let quizOptionFontFamily;
      if (
        field_item.options["quiz-option-font-family"] &&
        field_item.options["quiz-option-font-family"] !== "theme"
      ) {
        quizOptionFontFamily = field_item.options["quiz-option-font-family"];
      }

      let quizOptionFontLineHeight;
      if (
        field_item.options["quiz-option-font-line-height"] &&
        field_item.options["quiz-option-font-line-height"] !== "theme"
      ) {
        quizOptionFontLineHeight = field_item.options["quiz-option-font-line-height"];
      }

      let quizOptionFontWeight;
      if (
        field_item.options["quiz-option-font-weight"] &&
        field_item.options["quiz-option-font-weight"] !== "theme"
      ) {
        quizOptionFontWeight = field_item.options["quiz-option-font-weight"];
      }

      let quizOptionVisualColor = field_item.options["quiz-option-color"] || undefined;
      let quizHoverOptionVisualColor = field_item.options["quiz-hover-option-color"] || undefined;

      let quizOptionFontColor;
      if (
        field_item.options["quiz-option-font-color"] &&
        field_item.options["quiz-option-font-color"] !== "theme"
      ) {
        quizOptionFontColor = field_item.options["quiz-option-font-color"];
      }

      let quizOptionVisualFontSize = field_item.options["quiz-option-visual-font-size"] || undefined;

      let quizOptionFontSize;
      if (
        field_item.options["quiz-option-font-size"] &&
        field_item.options["quiz-option-font-size"] !== "theme"
      ) {
        quizOptionFontSize = field_item.options["quiz-option-font-size"];
      }

      let quizOptionWidth = "";
      if (
        field_item.options["quiz-option-width-value"] &&
        field_item.options["quiz-option-width-style"] == "custom"
      ) {
        quizOptionWidth = `${field_item.options["quiz-option-width-value"]}px`;
      } else if (field_item.options["quiz-option-width-style"] == "regular") {
        quizOptionWidth = "300px";
      } else if (field_item.options["quiz-option-width-style"] == "full") {
        quizOptionWidth = "100%";
      } else if (field_item.options["quiz-option-width-style"] == "auto") {
        quizOptionWidth = "auto";
      } else if (
        variant.options["quiz-option-width-value"] &&
        variant.options["quiz-option-width-style"] == "custom"
      ) {
        quizOptionWidth = `${variant.options["quiz-option-width-value"]}px`;
      } else if (variant.options["quiz-option-width-style"] == "regular") {
        quizOptionWidth = "300px";
      } else if (variant.options["quiz-option-width-style"] == "full") {
        quizOptionWidth = "100%";
      } else {
        quizOptionWidth = "auto";
      }

      let quizImagePlacement =
        field_item.options["quiz-image-placement"] ||
        variant.options["quiz-image-placement"] ||
        'centered';

      let quizOptionImageWidth = field_item.options["quiz-option-image-width"] || 110;
      let quizOptionImageHeight = field_item.options["quiz-option-image-height"] || quizOptionImageWidth;

      let quizVisualPlacement =
        field_item.options["quiz-visual-placement"] ||
        variant.options["quiz-visual-placement"] || 'centered';

      let quizImagePlacementWidth;
      if (quizImagePlacement == "centered") {
        quizImagePlacementWidth = "100%";
      } else if (quizImagePlacement == "left") {
        quizImagePlacementWidth = `${parseInt(quizOptionVisualFontSize) * 0.6}%`;
      }

      let quizVisualPlacementWidth;
      if (quizVisualPlacement == "centered") {
        quizVisualPlacementWidth = "100%";
      } else if (quizVisualPlacement == "left") {
        quizVisualPlacementWidth = quizOptionVisualFontSize
          ? `${parseInt(quizOptionVisualFontSize) * 0.6}%`
          : "10%";
      }

      let quizOptionColorSize = field_item.options["quiz-option-color-size"] || undefined;
      let quizSelectedOptionFontColor = field_item.options["quiz-selected-option-font-color"] || variant.options["quiz-selected-option-font-color"] || undefined;
      let quizHoverOptionFontColor = field_item.options["quiz-hover-option-font-color"] || variant.options["quiz-hover-option-font-color"] || undefined;
      let quizOptionBackgroundColor = field_item.options["quiz-option-background-color"] || variant.options["quiz-option-background-color"] || undefined;
      let quizSelectedOptionBackgroundColor = field_item.options["quiz-selected-option-background-color"] || variant.options["quiz-selected-option-background-color"] || undefined;

      let quizOptionBoxShadow;
      if (field_item.options["quiz-option-box-shadow"] == "soft") {
        quizOptionBoxShadow = "0 3px 5px rgb(179 179 179 / 78%)";
      }
      if (field_item.options["quiz-option-box-shadow"] == "hard") {
        quizOptionBoxShadow = "-3px 3px 0 rgb(0 0 0 / 10%)";
      }
      if (field_item.options["quiz-option-box-shadow"] == "none") {
        quizOptionBoxShadow = "unset";
      }
      if (field_item.options["quiz-option-box-shadow"] == "custom") {
        quizOptionBoxShadow = "";
        quizOptionBoxShadow +=
          (field_item.options["quiz-option-box-shadow-horizontal-distance"]
            ? `${field_item.options["quiz-option-box-shadow-horizontal-distance"]}px`
            : "0px") + " ";

        quizOptionBoxShadow +=
          (field_item.options["quiz-option-box-shadow-vertical-distance"]
            ? `${field_item.options["quiz-option-box-shadow-vertical-distance"]}px`
            : "0px") + " ";

        quizOptionBoxShadow +=
          (field_item.options["quiz-option-box-shadow-blur-radius"]
            ? `${field_item.options["quiz-option-box-shadow-blur-radius"]}px`
            : "0px") + " ";

        quizOptionBoxShadow +=
          (field_item.options["quiz-option-box-shadow-color"]
            ? field_item.options["quiz-option-box-shadow-color"]
            : "rgba(0,0,0, 0.35)") + " ";
      }

      let quizFieldFontSize;
      if (field_item.options["quiz-answer-field-font-size"]) {
        quizFieldFontSize = field_item.options["quiz-answer-field-font-size"];
      }

      let intFieldSizeValue = parseInt(field_item.options["quiz-answer-field-size-value"]);
      let quizFieldConsideredFontSize = quizFieldFontSize || variant.options["field-font-size"] || 15;
      let quizFieldInputPaddingFormula = Math.floor((quizFieldConsideredFontSize * 1.5) / 4 / 2);

      // prettier-ignore
      let customInputPaddingCalculated = `${intFieldSizeValue + quizFieldInputPaddingFormula}px
        ${intFieldSizeValue}px
        ${intFieldSizeValue + quizFieldInputPaddingFormula}px
        ${intFieldSizeValue}px`;

      // source: app/views/cta/_css.html.erb:2026
      let regularInputPadding = {
        top: 11,
        right: 0,
        bottom: 9,
        left: 10,
      };

      // source: app/views/cta/_css.html.erb:2074
      let largeInputPadding = {
        top: 12,
        right: 0,
        bottom: 10,
        left: 10,
      };

      // prettier-ignore
      let regularInputPaddingCalculated = `${regularInputPadding["top"] + quizFieldInputPaddingFormula}px
        ${regularInputPadding["right"]}px
        ${regularInputPadding["bottom"] + quizFieldInputPaddingFormula}px
        ${regularInputPadding["left"]}px`;

      // prettier-ignore
      let largeInputPaddingCalculated = `${largeInputPadding["top"] + quizFieldInputPaddingFormula}px
        ${largeInputPadding["right"]}px
        ${largeInputPadding["bottom"] + quizFieldInputPaddingFormula}px
        ${largeInputPadding["left"]}px`;

      let quizFieldFontWeight;
      if (
        field_item.options["quiz-answer-field-font-weight"] &&
        field_item.options["quiz-answer-field-font-weight"] !== "theme"
      ) {
        quizFieldFontWeight = field_item.options["quiz-answer-field-font-weight"];
      }

      let quizFieldRadius;
      if (field_item.options["quiz-answer-field-rounded"] == "cf-field-not-rounded") {
        quizFieldRadius = "0px";
      }
      if (field_item.options["quiz-answer-field-rounded"] == "cf-field-slightly-rounded") {
        quizFieldRadius = "3px";
      }
      if (field_item.options["quiz-answer-field-rounded"] == "cf-field-fully-rounded") {
        quizFieldRadius = "100px";
      }
      if (field_item.options["quiz-answer-field-rounded"] == "custom") {
        quizFieldRadius = "";
        ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
          (side) => {
            quizFieldRadius += `${
              _.isNil(field_item.options["quiz-answer-field-radius-" + side]) == false
                ? field_item.options["quiz-answer-field-radius-" + side]
                : "3"
            }px `;
          }
        );
      }

      let quizFieldBoxShadow;

      if (field_item.options["quiz-answer-field-box-shadow"] == "soft") {
        quizFieldBoxShadow = "0 5px 15px 0 rgb(179 179 179 / 78%)";
      }

      if (field_item.options["quiz-answer-field-box-shadow"] == "hard") {
        quizFieldBoxShadow = "-5px 5px 0 0 rgb(0 0 0 / 10%)";
      }

      if (field_item.options["quiz-answer-field-box-shadow"] == "custom") {
        quizFieldBoxShadow = "";
        quizFieldBoxShadow +=
          (field_item.options["quiz-answer-field-box-shadow-horizontal-distance"]
            ? `${field_item.options["quiz-answer-field-box-shadow-horizontal-distance"]}px`
            : "0px") + " ";

        quizFieldBoxShadow +=
          (field_item.options["quiz-answer-field-box-shadow-vertical-distance"]
            ? `${field_item.options["quiz-answer-field-box-shadow-vertical-distance"]}px`
            : "0px") + " ";

        quizFieldBoxShadow +=
          (field_item.options["quiz-answer-field-box-shadow-blur-radius"]
            ? `${field_item.options["quiz-answer-field-box-shadow-blur-radius"]}px`
            : "0px") + " ";

        quizFieldBoxShadow +=
          (field_item.options["quiz-answer-field-box-shadow-spread-radius"]
            ? `${field_item.options["quiz-answer-field-box-shadow-spread-radius"]}px`
            : "0px") + " ";

        quizFieldBoxShadow +=
          (field_item.options["quiz-answer-field-box-shadow-color"]
            ? field_item.options["quiz-answer-field-box-shadow-color"]
            : "rgba(0,0,0, 0.35)") + " ";
      }

      let quizOptionsLayout = field_item.options["quiz-options-layout"] || undefined;
      let quizOptionsVerticalSpacing = field_item.options["quiz-options-vertical-spacing"] || variant.options["quiz-options-vertical-spacing"] || 10
      let quizOptionsHorizontalSpacing = field_item.options["quiz-options-horizontal-spacing"] || variant.options["quiz-options-horizontal-spacing"] || 10

      let quizOptionVisualSpacing = field_item.options["quiz-option-visual-spacing"] || variant.options["quiz-option-visual-spacing"] || 15;

      Object.values(field_item.field_options).forEach((option) => {
        desktopStyles += `
          #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-option-id="${option.id}"] .cf-quiz-image-box {
            ${option.label ? '': 'width: 100% !important;'}
          }

          #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-option-id="${option.id}"] .cf-quiz-option-color,
          #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-option-id="${option.id}"] .cf-quiz-option-emoji,
          #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-option-id="${option.id}"] .cf-quiz-option-icon,
          #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-option-id="${option.id}"] .cf-quiz-option-letter {
            ${(quizVisualPlacement == "left") && quizOptionVisualSpacing ? `margin: ${quizOptionVisualSpacing}px !important;` : ''}
            ${quizVisualPlacement == "centered" && quizOptionVisualSpacing ? `margin-bottom: ${quizOptionVisualSpacing}px !important; margin-top: ${quizOptionVisualSpacing}px !important;` : ''}
            ${quizVisualPlacement == "centered" && quizOptionWidth == "auto" && !option.label && quizOptionVisualSpacing ? `margin-left: ${quizOptionVisualSpacing}px !important; margin-right: ${quizOptionVisualSpacing}px !important;` : ''}
          }

          #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-option-id="${option.id}"] .cf-quiz-visual-box[data-visual-type="image"],
          #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-option-id="${option.id}"] .cf-quiz-visual-box[data-visual-type="color"],
          #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-option-id="${option.id}"] .cf-quiz-visual-box[data-visual-type="icon"],
          #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-option-id="${option.id}"] .cf-quiz-visual-box[data-visual-type="emoji"] {
            ${!option.label ? 'margin-left: auto; margin-right: auto;' : ''}
          }
        `
      })

      let quizOptionBorderPlacement =
        field_item.options["quiz-option-border-placement"] ||
        variant.options["quiz-option-border-placement"] ||
        "on_option";

      let optionSelectedStyle = field_item.options["quiz-selected-option-style"] || variant.options["quiz-selected-option-style"] || 'transparent';

      let quizContentPlacement;
      if (field_item.options["quiz-content-placement"] == "below") {
        quizContentPlacement = "column-reverse";
      } else if (field_item.options["quiz-content-placement"] == "left") {
        quizContentPlacement = "row";
      } else if (field_item.options["quiz-content-placement"] == "right") {
        quizContentPlacement = "row-reverse";
      } else {
        quizContentPlacement = "column";
      }

      let quizContentWidth = field_item.options["quiz-content-width"];

      desktopStyles += `
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-image-box {
          ${quizImagePlacementWidth ? `width: ${quizImagePlacementWidth} !important;` : ""}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-content-answers-wrapper[data-field-id="${field_item.id}"] {
          ${quizContentPlacement ? `flex-direction: ${quizContentPlacement} !important;` : ''}
          ${quizContentWidth ? `max-width: ${quizContentWidth}px !important;` : ''}
          margin-left: auto;
          margin-right: auto;
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="image"] {
          ${quizImagePlacement == "centered" ? 'flex-direction: column;' : ''}
          ${quizImagePlacement == "left" ? 'flex-wrap: nowrap; gap: 10px;' : 'flex-wrap: wrap;'}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-option-image {
          ${quizImagePlacement == "left" ? 'display: block;' : 'display: inline;'}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] {
          ${quizOptionsVerticalSpacing ? `margin-top: ${quizOptionsVerticalSpacing}px !important; margin-bottom: ${quizOptionsVerticalSpacing}px !important;` : ''}
          ${options["cf-content-alignment"] == "cf-left" ? `margin-right: ${quizOptionsHorizontalSpacing*2}px !important;` : ''}
          ${options["cf-content-alignment"] == "cf-right" ? `margin-left: ${quizOptionsHorizontalSpacing*2}px !important;` : ''}
          ${options["cf-content-alignment"] == "cf-center" || !options["cf-content-alignment"] ? `margin-left: ${quizOptionsHorizontalSpacing}px !important; margin-right: ${quizOptionsHorizontalSpacing}px !important;` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question[data-field-id="${field_item.id}"] .cf-quiz-answers-wrapper {
          ${quizOptionsLayout == "responsive" ? 'flex-direction: row;' : ''}
          ${quizOptionsLayout == "stacked" ? 'flex-direction: column;' : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question[data-field-id="${field_item.id}"] .cf-quiz-answers-outer-wrapper {
          ${!field_item.options["label-image"] ? 'display: block;' : field_item.options["label-image-placement"] == "below" ? 'display: block !important;' : field_item.options["label-image-placement"] ? 'display: flex !important;' : ''}
          ${field_item.options["label-image-placement"] == "left" ? 'flex-direction: row !important;' : ''}
          ${field_item.options["label-image-placement"] == "right" ? 'flex-direction: row-reverse !important;' : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question[data-field-id="${field_item.id}"] .cf-quiz-inner-wrapper {
          ${['left', 'right'].includes(field_item.options["label-image-placement"]) ? 'margin-top: 0px; margin-bottom: auto;' : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question[data-field-id="${field_item.id}"] .cf-quiz-answers-outer-wrapper img {
          ${['left', 'right'].includes(field_item.options["label-image-placement"]) ? 'max-width: 80%;' : ''}
          ${field_item.options["label-image-placement"] == 'left' ? 'margin-right: 30px;' : ''}
          ${field_item.options["label-image-placement"] == 'right' ? 'margin-left: 30px;' : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question[data-field-id="${field_item.id}"] .cf-quiz-label-image {
          ${field_item.options["label-image-width"] ? `width: ${field_item.options["label-image-width"]}px;` : 'width: fit-content;'}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question[data-field-id="${field_item.id}"] .cf-loading-bar {
          ${field_item.options["loading-border-color"] ? `border-color: ${field_item.options["loading-border-color"]};` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question[data-field-id="${field_item.id}"] .cf-loading-bar:after {
          animation: ${field_item.options["auto-continue-delay"] || 5}s cf-quiz-loading-bar ease-in infinite;
          ${field_item.options["loading-background-color"] ? `background: ${field_item.options["loading-background-color"]};` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question[data-field-id="${field_item.id}"] .cf-loading-spinner::before {
          ${field_item.options["loading-border-color"] ? `border-color: ${field_item.options["loading-border-color"]};` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question[data-field-id="${field_item.id}"] .cf-loading-bar,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question[data-field-id="${field_item.id}"] .cf-loading-spinner {
          ${field_item.options["loading-spacing"] ? `margin-top: ${field_item.options["loading-spacing"]}px; margin-bottom: ${field_item.options["loading-spacing"]}px; ` : 'margin-bottom: 30px; margin-top: 30px;'}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-form-2 {
          ${field_item.options["quiz-answer-width"] == 'custom' && field_item.options["quiz-answer-width-value"] ? `max-width: ${field_item.options["quiz-answer-width-value"]}px !important;` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-visual-box {
          ${quizVisualPlacementWidth ? `min-width: ${quizVisualPlacementWidth} !important;` : ""}
          ${quizVisualPlacement == "centered" ? `margin-right: unset;` : ''}
          ${quizVisualPlacement == "left" ? `margin-bottom: unset;` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-option-color {
          ${quizOptionColorSize ? `width: ${quizOptionColorSize}px !important;` : ''}
          ${quizOptionColorSize ? `height: ${quizOptionColorSize}px !important;` : ''}
          color: transparent;
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-option-color:empty {
          display: none;
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-form-2.cf-form-theme-default input[type="text"]::placeholder,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-form-2.cf-form-theme-default input[type="email"]::placeholder,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-form-2.cf-form-theme-default input[type="tel"]::placeholder,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-form-2.cf-form-theme-default textarea::placeholder,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-form-2.cf-form-theme-default select::placeholder {
          ${_.isNil(field_item.options["quiz-answer-field-text-color"]) == false ? `color: ${field_item.options["quiz-answer-field-text-color"]} !important;` : ''}
          ${quizFieldFontWeight ? `font-weight: ${quizFieldFontWeight} !important;` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-form-2.cf-form-theme-default input[type="text"]:not(.cf-quiz-required),
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-form-2.cf-form-theme-default input[type="email"]:not(.cf-quiz-required),
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-form-2.cf-form-theme-default input[type="tel"]:not(.cf-quiz-required),
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-form-2.cf-form-theme-default input[type="number"]:not(.cf-quiz-required),
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-form-2.cf-form-theme-default textarea:not(.cf-quiz-required),
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-form-2.cf-form-theme-default select:not(.cf-quiz-required) {
          ${quizFieldBoxShadow ? 'box-shadow: ' + quizFieldBoxShadow + ' !important;' : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-form-2.cf-form-theme-default input[type="text"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-form-2.cf-form-theme-default input[type="email"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-form-2.cf-form-theme-default input[type="tel"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-form-2.cf-form-theme-default input[type="number"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-form-2.cf-form-theme-default textarea,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-form-2.cf-form-theme-default select {
          ${field_item.options["quiz-answer-field-size"] == 'custom' && _.isNil(field_item.options["quiz-answer-field-size-value"]) == false ? `padding: ${customInputPaddingCalculated} !important; padding-right: 5px !important;` : `padding: ${regularInputPaddingCalculated} !important;`}
          ${quizFieldFontWeight ? `font-weight: ${quizFieldFontWeight} !important;` : ''}
          ${_.isNil(field_item.options["quiz-answer-field-text-color"]) == false ? `color: ${field_item.options["quiz-answer-field-text-color"]} !important;` : ''}
          ${_.isNil(quizFieldRadius) == false ? `border-radius: ${quizFieldRadius} !important;` : ''}
          ${_.isNil(field_item.options["quiz-answer-field-background-color"]) == false ? `background: ${field_item.options["quiz-answer-field-background-color"]} !important;` : ''}
          ${_.isNil(field_item.options["quiz-answer-field-border-style"]) == false ? `border-style: ${field_item.options["quiz-answer-field-border-style"]} !important;` : ''}
          ${_.isNil(field_item.options["quiz-answer-field-border-color"]) == false ? `border-color: ${field_item.options["quiz-answer-field-border-color"]} !important;` : ''}
          ${_.isNil(field_item.options["quiz-answer-field-border-width"]) == false ? 'border-width: ' + field_item.options["quiz-answer-field-border-width"] + 'px !important;' : ''}
          ${_.isNil(field_item.options["quiz-answer-field-border-width-left"]) == false ? 'border-left-width: ' + field_item.options["quiz-answer-field-border-width-left"] + 'px !important;' : ''}
          ${_.isNil(field_item.options["quiz-answer-field-border-width-right"]) == false ? 'border-right-width: ' + field_item.options["quiz-answer-field-border-width-right"] + 'px !important;' : ''}
          ${_.isNil(field_item.options["quiz-answer-field-border-width-top"]) == false ? 'border-top-width: ' + field_item.options["quiz-answer-field-border-width-top"] + 'px !important;' : ''}
          ${_.isNil(field_item.options["quiz-answer-field-border-width-bottom"]) == false ? 'border-bottom-width: ' + field_item.options["quiz-answer-field-border-width-bottom"] + 'px !important;' : ''}
          ${field_item.options["quiz-answer-field-size"] != 'custom' ? `padding: ${largeInputPaddingCalculated} !important;` : ''}
          ${quizFieldFontSize ? `font-size: ${quizFieldFontSize}px !important;` : ''}
          ${field_item.options["quiz-answer-field-font"] ? `font-family: ${field_item.options["quiz-answer-field-font"]}, sans-serif !important;` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="image"] .cf-quiz-option-label {
          padding: 10px;
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="radio"] .cf-selection-options,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="checkbox"] .cf-selection-options {
          width: fit-content !important;
          text-align: left !important;
          ${quizContentAlignmentMargin ? quizContentAlignmentMargin : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-radio-option {
          ${field_item.options["quiz-option-text-alignment"] ? `text-align: ${field_item.options["quiz-option-text-alignment"]}` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-radio-option .cf-label {
          ${quizOptionFontColor ? `color: ${quizOptionFontColor};` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-radio-option .cf-label:hover {
          cursor: pointer;
          ${quizHoverOptionFontColor ? `color: ${quizHoverOptionFontColor} !important;` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-option-label {
          ${field_item.options["quiz-option-text-alignment"] == 'center' ? `margin-left: auto !important; margin-right: auto !important; text-align: center !important;` : ''}
          ${field_item.options["quiz-option-text-alignment"] == 'left' ? `margin-left: 0px !important; margin-right: auto !important; text-align: left !important;` : ''}
          ${field_item.options["quiz-option-text-alignment"] == 'right' ? `margin-right: 0px !important; margin-left: auto !important; text-align: right !important;` : ''}
          ${quizOptionFontFamily && quizOptionFontFamily !== 'inherit' ? `font-family: ${quizOptionFontFamily}, sans-serif;` : ''}
          ${quizOptionFontWeight ? `font-weight: ${quizOptionFontWeight};` : ''}
          ${quizOptionFontSize ? `font-size: ${quizOptionFontSize}px;` : ''}
          ${quizOptionFontLineHeight ? `line-height: ${quizOptionFontLineHeight}px;` : ''}
          ${quizOptionFontColor ? `color: ${quizOptionFontColor};` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="color"] .cf-quiz-option-label,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="icon"] .cf-quiz-option-label,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="letter"] .cf-quiz-option-label,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="emoji"] .cf-quiz-option-label {
          ${field_item.label && quizVisualPlacement == "left" && quizOptionWidth == "auto" ? 'padding-right: 10px;' : ''}
          ${field_item.label && quizVisualPlacement == "centered" ? 'padding-left: 10px; padding-right: 10px;' : ''}
          ${field_item.label && quizVisualPlacement == "centered" ? 'margin-bottom: 15px;' : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-option-letter {
          ${quizOptionVisualColor ? `border: 2px solid ${quizOptionVisualColor} !important;` : ''}
          ${quizOptionVisualColor ? `background-color: ${quizOptionVisualColor}14 !important;` : ''}
          ${quizOptionVisualColor ? `color: ${quizOptionVisualColor} !important;` : ""}
          ${quizOptionVisualFontSize ? `font-size: ${quizOptionVisualFontSize*0.77}px !important;` : ""}
          width: ${quizOptionVisualFontSize * 1.5}px !important;
          height: ${quizOptionVisualFontSize * 1.5}px !important;
        }


        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"]:hover .cf-quiz-option-letter {
          ${quizHoverOptionVisualColor ? `border: 2px solid ${quizHoverOptionVisualColor} !important;` : ''}
          ${quizHoverOptionVisualColor ? `background-color: ${quizHoverOptionVisualColor}14 !important;` : ''}
          ${quizHoverOptionVisualColor ? `color: ${quizHoverOptionVisualColor} !important;` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"].cf-option-selected {
          ${quizSelectedOptionBackgroundColor && optionSelectedStyle == "custom" ? `background-color: ${quizSelectedOptionBackgroundColor} !important;` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-option-icon .svg-inline--fa {
          ${quizOptionVisualFontSize ? `font-size: ${quizOptionVisualFontSize}px;` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-option-emoji,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-option-icon svg {
          ${quizOptionVisualColor ? `color: ${quizOptionVisualColor};` : ''}
          ${quizOptionVisualFontSize ? `font-size: ${quizOptionVisualFontSize}px;` : ''}
        }


        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"]:hover .cf-quiz-option-emoji,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"]:hover .cf-quiz-option-icon svg {
          ${quizHoverOptionVisualColor ? `color: ${quizHoverOptionVisualColor} !important;` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-option-image,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-option-image-svg {
          ${quizOptionImageWidth ? `width: ${quizOptionImageWidth}px !important;` : 'max-width: 250px;'}
          ${quizOptionImageHeight ? `height: ${quizOptionImageHeight}px !important;` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="survey"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="image"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="color"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="icon"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="letter"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="emoji"] {
          ${quizOptionWidth ? `width: ${quizOptionWidth};` : ''}
          ${quizOptionWidth == "custom" ? 'margin: 0px !important;' : ''}
          ${quizOptionBackgroundColor ? `background-color: ${quizOptionBackgroundColor};` : ''}
          ${quizOptionBoxShadow ? `box-shadow: ${quizOptionBoxShadow} !important;` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="survey"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="color"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="icon"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="letter"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="emoji"] {
          ${quizVisualPlacement == "left" ? 'flex-wrap: nowrap;' : 'flex-wrap: wrap;'}
          ${quizVisualPlacement == "left" ? 'gap: 0px;' : 'gap: 5px;'}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"].cf-option-selected .cf-quiz-visual-box[data-visual-type="image"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"].cf-option-selected .cf-quiz-visual-box[data-visual-type="color"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"].cf-option-selected .cf-quiz-visual-box[data-visual-type="icon"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"].cf-option-selected .cf-quiz-visual-box[data-visual-type="emoji"] {
          ${selectedOptionBorderStyles(field_item, quizOptionBorderPlacement == "on_visual" )}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="survey"].cf-option-selected,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="image"].cf-option-selected,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="color"].cf-option-selected,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="icon"].cf-option-selected,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="letter"].cf-option-selected,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="emoji"].cf-option-selected {
          ${selectedOptionBorderStyles(field_item, quizOptionBorderPlacement == "on_option" )}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="letter"].cf-option-selected .cf-quiz-option-letter {
          ${quizSelectedOptionFontColor && optionSelectedStyle == "custom" ? `color: ${quizSelectedOptionFontColor};` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"].cf-option-selected .cf-quiz-option-label {
          ${quizSelectedOptionFontColor && optionSelectedStyle == "custom" ? `color: ${quizSelectedOptionFontColor};` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"].cf-option-selected .cf-quiz-option-icon svg {
          ${quizSelectedOptionFontColor && optionSelectedStyle == "custom" ? `color: ${quizSelectedOptionFontColor};` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"].cf-option-selected .cf-quiz-option-letter {
          ${quizSelectedOptionFontColor ? `border: 2px solid ${quizSelectedOptionFontColor};` : ''}
          ${quizSelectedOptionFontColor ? `background-color: ${quizSelectedOptionFontColor}14;` : ''}
          ${quizSelectedOptionFontColor ? `color: ${quizSelectedOptionFontColor};` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-visual-box[data-visual-type="image"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-visual-box[data-visual-type="color"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-visual-box[data-visual-type="icon"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-visual-box[data-visual-type="emoji"] {
          ${optionBorderStyles(field_item, quizOptionBorderPlacement == "on_visual" )}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="survey"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="image"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="color"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="icon"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="letter"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="emoji"] {
          ${optionBorderStyles(field_item, quizOptionBorderPlacement == "on_option")}
        }


        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-visual-box[data-visual-type="image"]:hover,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-visual-box[data-visual-type="color"]:hover,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-visual-box[data-visual-type="icon"]:hover,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-visual-box[data-visual-type="emoji"]:hover {
          ${hoverOptionStyles(field_item, quizOptionBorderPlacement == "on_visual")}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="survey"]:hover,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="image"]:hover,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="color"]:hover,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="icon"]:hover,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="letter"]:hover,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="emoji"]:hover {
          ${hoverOptionStyles(field_item, quizOptionBorderPlacement == "on_option" )}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"]:hover .cf-quiz-option-label {
          ${quizHoverOptionFontColor ? `color: ${quizHoverOptionFontColor} !important;` : ''}
        }
      `;
    })

    let quizMaxWidthElementMobile = "";
    if (
      options["quiz-max-width-style-mobile"] == "custom" &&
      options["quiz-max-width-value-mobile"]
    ) {
      quizMaxWidthElementMobile = `${options["quiz-max-width-value-mobile"]}px`;
    } else if (options["quiz-max-width-style"] == "full") {
      quizMaxWidthElementMobile = "100%";
    }

    let quizButtonFontSizeMobile;
    if (options["quiz-button-font-size-mobile"]) {
      quizButtonFontSizeMobile = options["quiz-button-font-size-mobile"];
    }

    let quizQuestionFontSizeMobile;
    if (
      options["quiz-question-font-size-mobile"] &&
      options["quiz-question-font-size-mobile"] !== "theme"
    ) {
      quizQuestionFontSizeMobile = options["quiz-question-font-size-mobile"];
    }

    let quizQuestionLineHeightMobile;
    if (options["quiz-question-font-line-height-mobile"]) {
      quizQuestionLineHeightMobile =
        options["quiz-question-font-line-height-mobile"];
    }

    let quizDescriptionFontSizeMobile;
    if (
      options["quiz-description-font-size-mobile"] &&
      options["quiz-description-font-size-mobile"] !== "theme"
    ) {
      quizDescriptionFontSizeMobile =
        options["quiz-description-font-size-mobile"];
    }

    let quizDescriptionLineHeightMobile;
    if (options["quiz-description-font-line-height-mobile"]) {
      quizDescriptionLineHeightMobile =
        options["quiz-description-font-line-height-mobile"];
    }

    ////////

    let quizContentAlignmentMobile = "";
    let quizContentAlignmentMarginMobile = "";
    switch (options["cf-content-alignment-mobile"]) {
      case "cf-left":
        quizContentAlignmentMarginMobile = "margin-right: auto; margin-left: unset;";
        quizContentAlignmentMobile =
          "text-align: left !important; justify-content: flex-start !important; align-content: flex-start !important;";
        break;
      case "cf-center":
        quizContentAlignmentMarginMobile = "margin-right: auto; margin-left: auto;";
        quizContentAlignmentMobile =
          "text-align: center !important; justify-content: center !important; align-content: center !important;";
        break;
      case "cf-right":
        quizContentAlignmentMarginMobile = "margin-left: auto; margin-right: unset;";
        quizContentAlignmentMobile =
          "text-align: right !important; justify-content: flex-end !important; align-content: flex-end !important;";
        break;
    }

    let quizQuestionDistanceFromProgressMobile =
      options["quiz-question-distance-from-progress-mobile"];

    let quizQuestionSpacingMobile =
      options["quiz-question-spacing-mobile"] ||
      variant.options["quiz-question-spacing-mobile"] ||
      undefined;

    let quizButtonWidthMobile;
    if (options["quiz-button-width-mobile"] == "cf-button-slim") {
      quizButtonWidthMobile = "min-width: fit-content !important;"
    } else if (options["quiz-button-width-mobile"] == "cf-button-mid") {
      quizButtonWidthMobile = "min-width: 250px !important;"
    } else if (options["quiz-button-width-mobile"] == "cf-full-width") {
      quizButtonWidthMobile = "min-width: 100% !important;"
    } else if (options["quiz-button-width-mobile"] == "custom") {
      quizButtonWidthMobile = "min-width: " + options["quiz-button-width-value-mobile"] + "px !important;"
    }

    // prettier-ignore
    let mobileStyles =
    `/* mobile styles */
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-element {
      ${quizMaxWidthElementMobile ? `max-width: ${quizMaxWidthElementMobile};` : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress {
      ${quizQuestionDistanceFromProgressMobile ? `margin-bottom: ${quizQuestionDistanceFromProgressMobile}px !important;` : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-description,
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-label {
      ${quizQuestionSpacingMobile ? `margin-bottom: ${quizQuestionSpacingMobile}px;` : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-answers-wrapper {
      ${quizQuestionSpacingMobile ? `margin: ${quizQuestionSpacingMobile}px 0px;` : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-button {
      ${quizButtonWidthMobile ? quizButtonWidthMobile : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress,
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question,
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-answers-wrapper,
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-buttons-wrapper {
      ${quizContentAlignmentMobile ? quizContentAlignmentMobile : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-description, #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-description * {
      ${quizDescriptionFontSizeMobile ? `font-size: ${quizDescriptionFontSizeMobile}px;` : ''}
      ${quizDescriptionLineHeightMobile ? `line-height: ${quizDescriptionLineHeightMobile}px;` : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-label, #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-label * {
      ${quizQuestionFontSizeMobile ? `font-size: ${quizQuestionFontSizeMobile}px;` : ''}
      ${quizQuestionLineHeightMobile ? `line-height: ${quizQuestionLineHeightMobile}px !important;` : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message,
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message h1,
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message h2,
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message h3,
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message h4,
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message h5,
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message p,
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message span {
      ${options["quiz-progress-text-size-mobile"] ? `font-size: ${options["quiz-progress-text-size-mobile"]}px !important;` : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question .cf-quiz-progress-inner {
      ${quizProgressLabelFormat !== "box" && options['quiz-progress-height-mobile'] ? `min-height: ${options['quiz-progress-height-mobile']}px !important;` : ''}
      ${quizProgressLabelFormat == "box" && options['quiz-progress-inner-padding-mobile'] ? `padding: ${options['quiz-progress-inner-padding-mobile']}px !important;` : ''}
    }
    `;

    // INDIVIDUAL OPTIONS STYLES
    // prettier-ignore
    Object.values(sorted_fields).forEach((field_item) => {
      let quizOptionWidthMobile = "";
      if (
        field_item.options["quiz-option-width-style-mobile"] == "custom" &&
        field_item.options["quiz-option-width-value-mobile"]
      ) {
        quizOptionWidthMobile = `${field_item.options["quiz-option-width-value-mobile"]}px`;
      } else if (field_item.options["quiz-option-width-style-mobile"] == "regular") {
        quizOptionWidthMobile = "150px";
      } else if (field_item.options["quiz-option-width-style-mobile"] == "full") {
        quizOptionWidthMobile = "100%";
      } else if (field_item.options["quiz-option-width-style-mobile"] == "auto") {
        quizOptionWidthMobile = "auto";
      } else if (
        variant.options["quiz-option-width-style-mobile"] == "custom" &&
        variant.options["quiz-option-width-value-mobile"]
      ) {
        quizOptionWidthMobile = `${variant.options["quiz-option-width-value-mobile"]}px`;
      } else if (variant.options["quiz-option-width-style-mobile"] == "regular") {
        quizOptionWidthMobile = "300px";
      } else if (variant.options["quiz-option-width-style-mobile"] == "full") {
        quizOptionWidthMobile = "-webkit-fill-available";
      } else {
        quizOptionWidthMobile = "auto";
      }

      let quizOptionFontSizeMobile;
      if (
        field_item.options["quiz-option-font-size-mobile"] &&
        field_item.options["quiz-option-font-size-mobile"] !== "theme"
      ) {
        quizOptionFontSizeMobile = field_item.options["quiz-option-font-size-mobile"];
      }

      let quizOptionFontLineHeightMobile;
      if (
        field_item.options["quiz-option-font-line-height-mobile"] &&
        field_item.options["quiz-option-font-line-height-mobile"] !== "theme"
      ) {
        quizOptionFontLineHeightMobile =
          field_item.options["quiz-option-font-line-height-mobile"];
      }

      let quizImagePlacementMobile =
        field_item.options["quiz-image-placement-mobile"] ||
        variant.options["quiz-image-placement-mobile"];


      let quizVisualPlacementMobile =
        field_item.options["quiz-visual-placement-mobile"] ||
        variant.options["quiz-visual-placement-mobile"] || 'centered';

      let quizOptionVisualFontSizeMobile = field_item.options["quiz-option-visual-font-size-mobile"] || undefined;

      let quizImagePlacementWidthMobile;
      if (quizImagePlacementMobile == "centered" || !field_item.label) {
        quizImagePlacementWidthMobile = "100%";
      } else if (quizImagePlacementMobile == "left") {
        quizImagePlacementWidthMobile = `${
          parseInt(quizOptionVisualFontSizeMobile) * 0.6
        }%`;
      }

      let quizVisualPlacementWidthMobile;
      if (quizVisualPlacementMobile == "centered") {
        quizVisualPlacementWidthMobile = "100%";
      } else if (quizVisualPlacementMobile == "left") {
        quizVisualPlacementWidthMobile = quizOptionVisualFontSizeMobile
          ? `${parseInt(quizOptionVisualFontSizeMobile) * 0.6}%`
          : "10%";
      }

      let quizOptionColorSizeMobile = field_item.options["quiz-option-color-size-mobile"] || undefined;

      let quizFieldFontSizeMobile;
      if (field_item.options["quiz-answer-field-font-size-mobile"]) {
        quizFieldFontSizeMobile = field_item.options["quiz-answer-field-font-size-mobile"];
      }

      let quizConsideredFontSizeMobile =
        quizFieldFontSizeMobile ||
        field_item.options["quiz-answer-field-font-size"] ||
        variant.options["field-font-size-mobile"] ||
        variant.options["field-font-size"] ||
        15;

      let quizOptionsLayoutMobile = field_item.options["quiz-options-layout-mobile"]
      let quizOptionVisualSpacingMobile = field_item.options["quiz-option-visual-spacing-mobile"] || variant.options["quiz-option-visual-spacing-mobile"] || 15;

      let quizOptionImageWidthMobile = field_item.options["quiz-option-image-width-mobile"] || 110;
      let quizOptionImageHeightMobile = field_item.options["quiz-option-image-height-mobile"] || quizOptionImageWidthMobile;

      let quizOptionsHorizontalSpacing = field_item.options["quiz-options-horizontal-spacing-mobile"] || variant.options["quiz-options-horizontal-spacing-mobile"] || field_item.options["quiz-options-horizontal-spacing"] || variant.options["quiz-options-horizontal-spacing"] || 10
      let quizOptionsVerticalSpacing = field_item.options["quiz-options-vertical-spacing-mobile"] || variant.options["quiz-options-vertical-spacing-mobile"] || field_item.options["quiz-options-vertical-spacing"] || variant.options["quiz-options-vertical-spacing"] || undefined

      Object.values(field_item.field_options).forEach((option) => {
        mobileStyles += `
          #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-option-id="${option.id}"] .cf-quiz-option-color,
          #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-option-id="${option.id}"] .cf-quiz-option-emoji,
          #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-option-id="${option.id}"] .cf-quiz-option-icon,
          #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-option-id="${option.id}"] .cf-quiz-option-letter {
            ${quizVisualPlacementMobile == "left" && quizOptionVisualSpacingMobile ? `margin: ${quizOptionVisualSpacingMobile}px !important;` : ''}
            ${quizVisualPlacementMobile == "centered" && quizOptionVisualSpacingMobile ? `margin: ${quizOptionVisualSpacingMobile}px auto ${quizOptionVisualSpacingMobile}px auto !important;`  : ''}
            ${quizVisualPlacementMobile == "centered" && quizOptionWidthMobile == "auto" && !option.label && quizOptionVisualSpacingMobile ? `margin-left: ${quizOptionVisualSpacingMobile}px !important; margin-right: ${quizOptionVisualSpacingMobile}px !important;` : ''}
          }
        `
      })

      let quizContentPlacementMobile;
      if (field_item.options["quiz-content-placement-mobile"] == "below") {
        quizContentPlacementMobile = "column-reverse";
      } else if (field_item.options["quiz-content-placement-mobile"] == "left") {
        quizContentPlacementMobile = "row";
      } else if (field_item.options["quiz-content-placement-mobile"] == "right") {
        quizContentPlacementMobile = "row-reverse";
      } else {
        quizContentPlacementMobile = "column";
      }

      let quizContentWidthMobile = field_item.options["quiz-content-width-mobile"];

      mobileStyles += `
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-image-box {
          ${quizImagePlacementWidthMobile ? `width: ${quizImagePlacementWidthMobile} !important;` : ""}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="radio"] .cf-selection-options,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="checkbox"] .cf-selection-options {
          ${quizContentAlignmentMarginMobile ? quizContentAlignmentMarginMobile : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-content-answers-wrapper[data-field-id="${field_item.id}"] {
          ${quizContentPlacementMobile ? `flex-direction: ${quizContentPlacementMobile} !important;` : ''}
          ${quizContentWidthMobile ? `max-width: ${quizContentWidthMobile}px !important;` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="image"] {
          ${quizImagePlacementMobile == "centered" ? 'flex-direction: column;' : ''}
          ${quizImagePlacementMobile == "left" ? 'flex-wrap: nowrap;' : 'flex-wrap: wrap;'}
          ${quizImagePlacementMobile == "left" ? 'gap: 10px !important;' : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-option-image {
          ${quizImagePlacementMobile == "left" ? 'display: block;' : 'display: inline;'}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] {
          ${options["cf-content-alignment-mobile"] == "cf-left" ? `margin: ${quizOptionsVerticalSpacing || quizOptionsHorizontalSpacing}px ${quizOptionsHorizontalSpacing*2}px ${quizOptionsVerticalSpacing || quizOptionsHorizontalSpacing}px 0px !important;` : ''}
          ${options["cf-content-alignment-mobile"] == "cf-right" ? `margin: ${quizOptionsVerticalSpacing || quizOptionsHorizontalSpacing}px 0px ${quizOptionsVerticalSpacing || quizOptionsHorizontalSpacing}px ${quizOptionsHorizontalSpacing*2}px !important;` : ''}
          ${options["cf-content-alignment-mobile"] == "cf-center" || !options["cf-content-alignment-mobile"] ? `margin: ${quizOptionsVerticalSpacing || quizOptionsHorizontalSpacing}px ${quizOptionsHorizontalSpacing}px !important;` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question[data-field-id="${field_item.id}"] .cf-quiz-answers-wrapper {
          ${quizOptionsLayoutMobile == "responsive" ? 'flex-direction: row !important; color: 1;' : ''}
          ${quizOptionsLayoutMobile == "stacked" ? 'flex-direction: column !important;' : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question[data-field-id="${field_item.id}"] .cf-loading-bar,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question[data-field-id="${field_item.id}"] .cf-loading-spinner {
          ${field_item.options["loading-spacing-mobile"] ? `margin-top: ${field_item.options["loading-spacing-mobile"]}px; margin-bottom: ${field_item.options["loading-spacing-mobile"]}px; ` : 'margin-bottom: 30px; margin-top: 30px;'}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question[data-field-id="${field_item.id}"] .cf-quiz-answers-outer-wrapper {
          ${field_item.options["label-image-placement-mobile"] == "below" ? 'display: block !important;' : field_item.options["label-image-placement-mobile"] ? 'display: flex !important;' : ''}
          flex-wrap: wrap;
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question[data-field-id="${field_item.id}"] .cf-quiz-answers-outer-wrapper img {
          max-width: 100%;
          margin-left: auto;
          margin-right: auto;
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question[data-field-id="${field_item.id}"] .cf-quiz-label-image {
          ${field_item.options["label-image-width-mobile"] ? `width: ${field_item.options["label-image-width-mobile"]}px;` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-form-2 select.cf-sms-countries {
          ${quizConsideredFontSizeMobile ? `font-size: ${quizConsideredFontSizeMobile - 2}px !important;` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-option-color {
          ${quizOptionColorSizeMobile ? `width: ${quizOptionColorSizeMobile}px !important;` : ''}
          ${quizOptionColorSizeMobile ? `height: ${quizOptionColorSizeMobile}px !important;` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-option-letter {
          ${quizOptionVisualFontSizeMobile ? `font-size: ${quizOptionVisualFontSizeMobile*0.77}px !important;` : ""}
          width: ${quizOptionVisualFontSizeMobile * 1.5}px !important;
          height: ${quizOptionVisualFontSizeMobile * 1.5}px !important;
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-option-emoji,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-option-icon svg {
          ${quizOptionVisualFontSizeMobile ? `font-size: ${quizOptionVisualFontSizeMobile}px;` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-option-icon .svg-inline--fa {
          ${quizOptionVisualFontSizeMobile ? `font-size: ${quizOptionVisualFontSizeMobile}px !important;` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-visual-box {
          ${quizVisualPlacementWidthMobile ? `min-width: ${quizVisualPlacementWidthMobile} !important;` : ""}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="color"] .cf-quiz-option-label,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="icon"] .cf-quiz-option-label,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="letter"] .cf-quiz-option-label,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="emoji"] .cf-quiz-option-label {
          ${field_item.label && quizVisualPlacementMobile == "left" ? 'padding-right: 15px; margin-bottom: 0px;' : ''}
          ${field_item.label && quizVisualPlacementMobile == "centered" ? 'margin-bottom: 15px; margin-right: auto;' : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-option-label {
          ${quizOptionFontSizeMobile ? `font-size: ${quizOptionFontSizeMobile}px;` : ''}
          ${quizOptionFontLineHeightMobile ? `line-height: ${quizOptionFontLineHeightMobile}px;` : ''}
          ${field_item.options["quiz-option-text-alignment-mobile"] == 'center' ? `margin-left: auto !important; margin-right: auto !important; text-align: center !important;` : ''}
          ${field_item.options["quiz-option-text-alignment-mobile"] == 'left' ? `margin-left: 0px !important; margin-right: auto !important; text-align: left !important;` : ''}
          ${field_item.options["quiz-option-text-alignment-mobile"] == 'right' ? `margin-right: 0px !important; margin-left: auto !important; text-align: right !important;` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-form-2.cf-form-theme-default input[type="text"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-form-2.cf-form-theme-default input[type="email"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-form-2.cf-form-theme-default input[type="tel"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-form-2.cf-form-theme-default input[type="number"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-form-2.cf-form-theme-default textarea,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-form-2.cf-form-theme-default select {
          ${quizFieldFontSizeMobile ? `font-size: ${quizFieldFontSizeMobile}px !important;` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-option-image,
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"] .cf-quiz-option-image-svg {
          ${quizOptionImageWidthMobile ? `width: ${quizOptionImageWidthMobile}px !important;` : ''}
          ${quizOptionImageHeightMobile ? `height: ${quizOptionImageHeightMobile}px !important;` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="survey"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="image"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="color"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="icon"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="letter"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="emoji"] {
          ${quizOptionWidthMobile ? `width: ${quizOptionWidthMobile} !important;` : ''}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="survey"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="color"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="icon"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="letter"],
        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-quiz-question-option[data-field-id="${field_item.id}"][data-field-type="emoji"] {
          ${quizVisualPlacementMobile == "left" ? 'flex-wrap: nowrap;' : 'flex-wrap: wrap;'}
          ${quizVisualPlacementMobile == "left" ? 'gap: 0px;' : 'gap: 5px;'}
        }
      `;
    })

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },
};

export default React.memo(ElementQuiz, arePropsEqual);
